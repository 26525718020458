import React, { useEffect } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAuth } from '../../helpers/AuthContext';

const ErrorBanner = () => {

    const { error, setError } = useAuth()

    useEffect(() => {
        const errorTimeout = async () => {
            await new Promise(resolve => setTimeout(resolve, 7000))
            setError(undefined)
        }
        if (error != undefined) {
            errorTimeout()
        }
    }, [error])

    return (
        <>
            {error && <div className='flex rounded-full px-8 py-1 align-middle justify-center w-fit bg-red bg-opacity-30 font-poppins font-normal'>
                <ErrorOutlineIcon sx={{ color: "red" }} />
                {error}
            </div>}
        </>
    )
}

export default ErrorBanner