import { InputLabel, Select, MenuItem, Radio, FormControlLabel, RadioGroup, FormControl, Typography, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';
import styles from '../../../Styles/GeneralStyles';
import { useAuth } from '../../../helpers/AuthContext';

const CreateProfileDialog = () => {
    const [open, setOpen] = useState(false);
    const [brandName, setBrandName] = useState('');
    const [brandDescription, setBrandDescription] = useState('');
    const [brandWebsite, setBrandWebsite] = useState('');
    const [idealCustomer, setIdealCustomer] = useState('');
    const [idealCustomerInterests, setIdealCustomerInterests] = useState('');
    const [brandColors, setBrandColors] = useState(['#FFFFFF', '#000000', '#ABB8C3']);
    const [visualStyle, setVisualStyle] = useState('');
    const [toneOfVoice, setToneOfVoice] = useState('');
    const languages = ['English', 'Arabic', 'Spanish', 'Danish', 'French', 'Italian', 'Dutch', 'German', 'Portuguese', 'Swedish'];
    // eslint-disable-next-line
    const [language, setLanguage] = useState("English");
    const [type, setType] = useState("service")
    const { createAgencyProfile, profileCount, profiles, mobile, multipleProfiles } = useAuth()

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleColorChange = (index, color) => {
        const updatedColors = [...brandColors];
        updatedColors[index] = color.hex;
        setBrandColors(updatedColors);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value)
    }

    const handleSubmit = async () => {
        const profileData = {
            brandName,
            brandDescription,
            brandWebsite,
            idealCustomer,
            idealCustomerInterests,
            brandColors,
            visualStyle,
            toneOfVoice,
            language,
            type
        };
        await createAgencyProfile(profileData)

        handleClose();
    };


    return (
        <div>
            {(profiles.length < profileCount) &&
                <>
                    <div className='relative group'>
                        <button
                            disabled={!multipleProfiles}
                            className='bg-socoBlue px-4 py-[7px] font-poppins font-bold text-white hover:bg-opacity-95 hover:scale-105 border-2 border-socoBlue transition-all rounded-2xl'
                            onClick={handleOpen}
                        >
                            Create Profile
                        </button>
                        {!multipleProfiles && (
                            <div className='absolute bg-white px-2 py-1 mt-5 -translate-x-16 border-socoBlue text-center border-2 w-[280px] rounded-full font-poppins font-bold scale-0 transition-all group-hover:scale-100'>
                                <p>Upgrade to create more profiles!</p>
                            </div>
                        )}
                    </div>
                </>
            }
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={styles.intro}>Create New Profile</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Brand Name"
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Brand Description"
                        value={brandDescription}
                        onChange={(e) => setBrandDescription(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Brand Website"
                        value={brandWebsite}
                        onChange={(e) => setBrandWebsite(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Ideal Customer"
                        value={idealCustomer}
                        onChange={(e) => setIdealCustomer(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Ideal Customer Interests"
                        value={idealCustomerInterests}
                        onChange={(e) => setIdealCustomerInterests(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <div>
                        Brand Colors:
                        {brandColors.map((color, index) => (
                            <div key={index}>
                                <input
                                    type="color"
                                    disabled={true}
                                    value={color}
                                    style={{ ...styles.colorInput, appearance: 'none' }}
                                />
                                <div style={{ paddingBottom: '20px' }}>
                                    <TwitterPicker
                                        color={color}
                                        onChange={(color) => handleColorChange(index, color)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <TextField
                        label="Visual Style"
                        value={visualStyle}
                        onChange={(e) => setVisualStyle(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Tone of Voice"
                        value={toneOfVoice}
                        onChange={(e) => setToneOfVoice(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <InputLabel id="language-label">Language</InputLabel>
                    <Select
                        labelId="language-label"
                        id="language-select"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                                {lang}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography style={mobile ? styles.introMobile : styles.intro}>
                        Are you promoting products or services?
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="promotion type"
                            name="type"
                            value={type}
                            onChange={handleTypeChange}
                            style={styles.radioGroup}
                        >
                            <FormControlLabel
                                value="product"
                                control={<Radio />}
                                label="Products"
                            />
                            <FormControlLabel
                                value="service"
                                control={<Radio />}
                                label="Services"
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button style={styles.Button} onClick={handleSubmit} variant="contained" color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default CreateProfileDialog;
