const styles = {
  Logout: {
    marginLeft: 'auto',
    backgroundColor: '#013b82',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '600',
  },
  Logo: {
    width: '150px',
  },
  Header: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    borderBottom: '1px solid #ccc',
    position: 'fixed',
    width: '100%',
    zIndex: 5
  },
  PositionRight: {
    marginLeft: 'auto',
  },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  Button: {
    backgroundColor: '#013b82',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '600',
    color: '#FFFFFF',
    margin: '20px 0px',
    alignItems: 'center',

  },
};
export default styles;
