import React, { useState } from 'react'
import SocoLogo from "../../assets/images/SocoLogo.png"
import { useNavigate } from 'react-router-dom';
import Link from '../Header/Link';
import { Logout, NotificationImportantRounded, NotificationsNone } from '@mui/icons-material';
import { useAuth } from '../../helpers/AuthContext';
import BellNotifications from '../BellNotifications';
import ContactUsDialog from '../Support/ContactUs';
import { Modal, Box, Typography } from '@mui/material';
import styles from '../../Styles/headerStyle';
import DropdownMenu from './DropdownMenu';

const Header = () => {
  const navigate = useNavigate()
  const { logout, logged, notifications, trial, historyVisible, user, setDropDownNavigation, dropDownNavigation, supportOpen, handleSupportClose } = useAuth()
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false)

  const handleDropDownToggle = () => {
    setDropDownNavigation(!dropDownNavigation)
  }

  const handleNotificationsClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };
  const handleLogout = () => {
    logout();
  };
  const handleDismiss = () => {
    setNotificationsOpen(false)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleHistoryPage = () => {
    if (historyVisible) {
      navigate('/history');
    }
  };
  return (
    <>
      {trial && logged && <nav className='w-full bg-white top-0 border-gray sticky px-16 py-2'>
        <p className='font-poppins text-center text-black'>Get the full experience! Check our available subscriptions <a className='underline font-bold' href='https://sococal.ai/pages/plans-pricing' target="_blank">here</a></p>
      </nav>}
      <nav className='w-full shadow-md z-50 backdrop-blur-lg bg-white bg-opacity-90 top-0 border-gray sticky px-16 py-3 flex items-center'>
        <img
          onClick={() => navigate('/generate')}
          className="h-9 duration-300 hover:scale-110 hover:cursor-pointer" src={SocoLogo} alt='Sococal Logo' />
        {logged &&
          <>
            <div className='absolute flex right-[2%] justify-center align-middle items-center'>
              <Link
                className='mx-6'
                placeholder="Generate"
                href="/generate"
              />
              <button className={` ${!historyVisible && " group"}`} onClick={handleHistoryPage}>
                <Link
                  className={`mx-6 ${!historyVisible && " text-gray hover:text-gray"}`}
                  placeholder="History"
                  href="#"
                />
                <div className='px-2 transition-all group-hover:scale-100 border-socoBlue top-12 right-96 absolute text-black font-poppins font-bold scale-0 border-2 rounded-full bg-white'>
                  <p>Upgrade to access history!</p>
                </div>
              </button>
              <Link
                className='mx-6 '
                placeholder="Profile"
                href="/profile"
              />
              <ContactUsDialog isOpen={supportOpen} onClose={handleSupportClose} />
              <Link
                className='mx-6 '
                placeholder="Integrations"
                href="/integrations"
              />
              <div className="top-100% mt-40 absolute right-[7%]">
                <BellNotifications
                  isOpen={notificationsOpen}
                  onClose={handleDismiss}
                />
              </div>
              <div className=' flex flex-auto rounded mx-4 p-1 hover:bg-socoBlue hover:bg-opacity-5 border-black border-opacity-30 text-sm font-poppins justify-center items-center'>
                {notifications.length > 0 ?
                  <button className='hover:cursor-pointer text-red duration-100 text-red-800 transition ease-in' onClick={handleNotificationsClick}>
                    <NotificationImportantRounded />
                  </button> :
                  <button className='hover:cursor-pointer  duration-100  transition ease-in' onClick={handleNotificationsClick}>
                    <NotificationsNone />
                  </button>
                }
              </div>
              <div className='flex flex-col'>
                <div onClick={handleDropDownToggle} className=' font-medium w-10 h-10 hover:cursor-pointer hover:bg-opacity-5 transition-all text-white hover:text-black flex flex-auto bg-socoBlue rounded-[50%] p-2 border-black border-opacity-30 text-lg font-poppins justify-center items-center'>
                  {user?.r_soco_users?.full_name?.charAt(0) ? user.r_soco_users.full_name.charAt(0).toUpperCase() : user?.r_soco_users?.first_name?.charAt(0) ? user.r_soco_users.first_name.charAt(0).toUpperCase() : "S"}
                </div>
                {dropDownNavigation && <DropdownMenu onClick={handleDropDownToggle}/>}
              </div>
            </div>
          </>
        }
      </nav>

    </>
  )
}

export default Header