import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Link = ({ className = "", href, placeholder }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentRoute = location.pathname;
  const handleNavigate = () => {
    if (href !== "#") {
      navigate(href)
    }
  }

  return (
    <p onClick={handleNavigate} className={`hover:cursor-pointer trnasition ease-linear duration-100 font-poppins hover:scale-110 hover:text-black text-gray-600 text-[0.85rem]  ${currentRoute === "/" + placeholder.toLowerCase() ? 'scale-105 font-bold' : 'font-light'} ${className}`}>
      {placeholder}
    </p>
  )
}

export default Link