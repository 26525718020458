import React, { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { Settings } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { useAuth } from '../../helpers/AuthContext';
import ContactUsDialog from '../Support/ContactUs';

const DropdownMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { logout, handleDropDownToggle, handleSupportClose } = useAuth()

    const handleFormClose = () => {
        setIsOpen(false)
    }
    const handleFormOpen = () => {
        setIsOpen(true)
    }


    return (
        <>
            <div onClick={handleDropDownToggle} className="absolute right-0 mt-12 w-44 bg-white rounded-md shadow-lg border-2 border-socoBlue border-opacity-10 select-none cursor-pointer">
                <div className=" divide-y divide-socoBlue divide-opacity-5 font-poppins">
                    {/* <div className='flex flex-row items-center px-4'>
                    <Settings sx={{ fontSize: "15px" }} />
                    <a
                        href="#"
                        className="block px-2 py-2 text-[12px] hover:bg-gray-100"
                    >
                        Account Settings
                    </a>
                </div> */}
                    <div onClick={handleSupportClose} className='flex flex-row items-center px-4 hover:bg-[#f3f4f6]'>
                        <HelpIcon sx={{ fontSize: "15px" }} />
                        <a
                            className="block px-2 py-2 text-[12px]"
                        >
                            Support
                        </a>
                    </div>
                    <ContactUsDialog isOpen={isOpen} onClose={handleFormClose} />
                    <div onClick={logout} className='flex flex-row items-center px-4 hover:bg-[#f3f4f6]'>
                        <LogoutIcon sx={{ fontSize: "15px" }} />
                        <a
                            className="block px-2 py-2 text-[12px]"
                        >
                            Logout
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DropdownMenu;
