import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Modal, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import styles from '../../Styles/GeneralStyles';
import DayOutputBox from '../../components/Generation/DayOutputBox';
import { useAuth } from '../../helpers/AuthContext';
import WelcomeTutorial from '../../components/Dialogs/WelcomeTutorial';

export default function Generate() {
    const [items, setItems] = useState([{ name: '', description: '', callToAction: '' }]);
    const [error, setError] = useState('');
    const [goal, setGoal] = useState('');
    const { user, getGenerationPrompts, generationsLeft, setGenerationsLeft, fulfillGeneration, shopify, regenerateCaption, facebookConfigsVisible,
        refreshImages, refreshCaptions, generateImages, syncGeneration, refreshCaptionsCount, refreshImagesCount, logError, selectedProfile, downloadCalendar,
        calendarDownload, multipleProfiles, mobile, trial, setRefreshCaptionsCount, setRefreshDayCount, setRefreshImagesCount, facebookIntegration, instagram
    } = useAuth();
    const [selectedPlatform, setSelectedPlatform] = useState(facebookIntegration && selectedProfile?.integrations?.some(integration => (
        integration.integration_type === "Facebook")) ? "facebookConnection" : instagram && selectedProfile?.integrations?.some(integration => (
            integration.integration_type === "Instagram")) ? "instagramConnection" : 'LinkedIn');
    const [isLoading, setIsLoading] = useState(true);
    const [generationState, setGenerationState] = useState();
    const [done, setDone] = useState(false)
    const [load, setLoad] = useState(false)
    const [prompts, setPrompts] = useState([])
    const [results, setResults] = useState([])
    const matches = useMediaQuery('(min-width:600px)');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [progress, setProgress] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [userInput, setUserInput] = useState()
    const [captionRegen, setCaptionRegen] = useState(0);
    const [imageRegen, setImageRegen] = useState(0);
    const [facebookOptimize, setFacebookOptimize] = useState(selectedPlatform === "facebookConnection" ? 'Likes' : selectedPlatform === "instagramConnection" ? 'Reach' : "Likes");
    const [modalMessage, setModalMessage] = useState("")
    const [startingDate, setStartingDate] = useState(undefined)
    const [fulfilledAt, setFulfilledAt] = useState()
    const [imageArr, setImageArr] = useState([])
    const [englishPrompts, setEnglishPrompts] = useState([])

    const { Configuration, OpenAIApi } = require("openai");
    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    const openai = new OpenAIApi(configuration);

    const DateExtractor = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const handleCalendarDownload = async () => {
        const res = await downloadCalendar(results, DateExtractor(fulfilledAt))
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${DateExtractor(fulfilledAt)}.csv`);
        document.body.appendChild(link);
        link.click();

    }


    useEffect(() => {
        const fetchUser = async () => {
            // await user to be available
            await user;
            //check if user is still in prmose state
            setIsLoading(false);
        };

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (load) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [load]);

    useEffect(() => {
        let timer = null;
        if (isRunning) {
            timer = setInterval(() => {
                setProgress((prevProgress) => {
                    const decrementValue = selectedProfile.language !== "English" ? 0.2 : 0;
                    return prevProgress >= 100
                        ? 100
                        : facebookConfigsVisible
                            ? prevProgress + 0.3 - decrementValue
                            : prevProgress + 0.4 - decrementValue;
                });
            }, 100);
        }
        return () => {
            clearInterval(timer);
        };
    }, [isRunning]);


    const handleFacebookOptimizeChange = (e) => {
        setFacebookOptimize(e.target.value);
    }

    const handlePlatformChange = (e) => {
        if (e.target.value == "instagramConnection") {
            setFacebookOptimize("Reach")
        } else if (e.target.value == "facebookConnection") {
            setFacebookOptimize("Likes")
        } else {
            setFacebookOptimize("Likes")
        }
        setSelectedPlatform(e.target.value);
    }

    const handleItemChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [name]: value };
        setItems(newItems);
    };

    const handleAddItem = () => {
        if (items.length < 10) {
            const newItem = { name: '', description: '', callToAction: '' };
            const requiredFields = Object.keys(newItem);
            if (items.every((item) => requiredFields.every((field) => item[field].trim()))) {
                setItems([...items, newItem]);
                setError('');
            } else {
                setError('Please fill out all fields before adding a new item.');
            }
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setRefreshDayCount(user.subscription_metric.refreshDayCount)
            setRefreshImagesCount(user.subscription_metric.refreshImagesCount)
            setRefreshCaptionsCount(user.subscription_metric.refreshCaptionsCount)
            setIsRunning(true);
            setImageRegen(0)
            setCaptionRegen(0)
            setLoad(true)
            setError('')
            setResults(null)
            setFulfilledAt()
            setDone(false)
            setProgress(0)
            const id = user.user.id ? user.user.id : '';
            const urls = [
                process.env.REACT_APP_COLOR_API + user.userProfile[0].brandColors[0].substring(1),
                process.env.REACT_APP_COLOR_API + user.userProfile[0].brandColors[1].substring(1),
                process.env.REACT_APP_COLOR_API + user.userProfile[0].brandColors[2].substring(1),
            ];
            let colorNames
            await Promise.all(
                urls.map(url => fetch(url).then(response => response.json()))
            )
                .then(data => {
                    colorNames = data.map(color => color.name.value)
                })
            const userAttributes = {
                "id": id,
                "items": items,
                "platform": selectedPlatform,
                "goal": goal,
                "facebookOptimize": facebookOptimize,
                "colors": colorNames,
                "startingDate": startingDate === undefined ? "undefined" : startingDate['$y'] + "-" + (startingDate['$M'] + 1) + "-" + (startingDate['$D'])
            };
            setUserInput(userAttributes)
            const res = await getGenerationPrompts(userAttributes)
            if (res === false) {
                setError("You Have No Generation Left")
                setModalMessage(<span>Sorry, you have no more generations left. Please head over to <a href="https://sococal.com/collections/pricing" rel="noopener noreferrer">sococal.com/collections/pricing</a> to upgrade your plan.</span>)
                setOpen(true)
                setLoad(false)
                setIsRunning(false)
                setProgress(0)
                return ""
            }
            const dates = res[0][1]
            const prompts = res[0][0][0]
            const images = res[0][0][1]
            setPrompts(res[0][0][0])
            const timeStamps = [res[1].metrics.textCompletionsTime, res[1].metrics.textCompletionsRetry, res[1].metrics.imageCompletionsTime, res[1].metrics.imageCompletionsRetry]
            var unresolved = true
            var unres = true
            var timeFulfilled = undefined
            const imageArray = new Array(prompts.length)
            const arr = new Array(prompts.length)
            const untranslatedPrompts = new Array(prompts.length)
            setGenerationsLeft(generationsLeft - 1)
            while (unresolved || unres) {
                for (let j = 0; j < arr.length; j++) {
                    unresolved = false
                    unres = false
                    if (untranslatedPrompts[j] === undefined) {
                        unresolved = true
                        unres = true
                        // eslint-disable-next-line
                        const promise = new Promise((resolve, reject) => {
                            unresolved = false
                            unres = false
                            setTimeout(() => reject(new Error("Timeout")), timeStamps[0])
                            openai.createChatCompletion({ model: "gpt-3.5-turbo", messages: prompts[j], temperature: 1.1 })
                                .then(response => resolve(response))
                                .catch(error => reject(error))
                        })
                        // eslint-disable-next-line
                        promise.then(response => {
                            let temp = response.data.choices[0].message.content.split(/(?:Caption:)/i)
                            let cap = temp[1]
                            let vis = temp[0].split(/(?:Visual Idea:)/i)[1]
                            let time = dates[j][1] + " " + dates[j][2] + ", " + dates[j][4]
                            if (selectedProfile.language === "English") {
                                arr[j] = [cap, vis, time]
                            }
                            untranslatedPrompts[j] = [cap, vis, time]
                            unresolved = false
                            unres = false
                            // eslint-disable-next-line
                        }).catch(error => {
                            console.error(error)
                            unresolved = true
                            unres = true
                        })
                    }
                }
                await new Promise(resolve => setTimeout(resolve, timeStamps[0]))
                setEnglishPrompts(untranslatedPrompts)
                if (selectedProfile.language === "English") {
                    setDone(true)
                    setResults(arr);
                    setIsRunning(false);
                    if (timeFulfilled === undefined) {
                        var r_res = await fulfillGeneration(userAttributes, arr.filter(item => item !== null), { genInput: userAttributes, profile: selectedProfile })
                        setFulfilledAt(r_res.data[0].fulfilled_at)
                        timeFulfilled = r_res.data[0].fulfilled_at
                    } else {
                        syncGeneration(r_res.data[0].fulfilled_at, arr.filter(item => item !== null), [])
                    }
                } else {
                    for (let q = 0; q < 2; q++) {
                        for (let j = 0; j < arr.length; j++) {
                            unresolved = false
                            if (arr[j]?.[q] === undefined && untranslatedPrompts[j] !== undefined) {
                                unresolved = true
                                // eslint-disable-next-line
                                const promise = new Promise((resolve, reject) => {
                                    unresolved = false
                                    setTimeout(() => reject(new Error("Timeout")), timeStamps[0] + 10000)
                                    openai.createChatCompletion({ model: "gpt-3.5-turbo", messages: [{ "role": "user", "content": "Translate all the english words to " + selectedProfile.language + " while keeping it sound native\n\n" + untranslatedPrompts[j][q] }], temperature: 0 })
                                        .then(response => resolve(response))
                                        .catch(error => reject(error))
                                })
                                // eslint-disable-next-line
                                promise.then(response => {
                                    if (!(Array.isArray(arr[j]))) {
                                        arr[j] = [undefined, undefined, undefined]
                                    }
                                    let time = dates[j][1] + " " + dates[j][2] + ", " + dates[j][4]
                                    arr[j][q] = response.data.choices[0].message.content
                                    arr[j][2] = time
                                    unresolved = false
                                    // eslint-disable-next-line
                                }).catch(error => {
                                    console.error(error)
                                    unresolved = true
                                })
                            }
                        }
                    }
                    await new Promise(resolve => setTimeout(resolve, timeStamps[0] + 10000))
                    setIsRunning(false)
                    setProgress(0)
                    setResults(arr);
                    setDone(true)
                    if (timeFulfilled === undefined) {
                        r_res = await fulfillGeneration(userAttributes, arr.filter(item => item !== null), { genInput: userAttributes, profile: selectedProfile })
                        setFulfilledAt(r_res.data[0].fulfilled_at)
                        timeFulfilled = r_res.data[0].fulfilled_at
                    } else {
                        syncGeneration(r_res.data[0].fulfilled_at, arr.filter(item => item !== null), [])
                    }
                }

            }
            if (generateImages) {
                unresolved = false
                for (let j = 0; j < prompts.length; j++) {
                    if (images[j] !== null) {
                        arr[j].push(images[j])
                    } else {
                        const promise = new Promise((resolve, reject) => {
                            setTimeout(() => reject(new Error("Timeout")), timeStamps[2])
                            openai.createImage({ prompt: (untranslatedPrompts[j][1]), n: 1, size: "1024x1024" })
                                .then(response => resolve(response))
                                .catch(error => reject(error))
                        })

                        promise.then(response => {
                            imageArray[j] = response
                            arr[j].push(response.data.data[0].url)
                            // eslint-disable-next-line
                        }).catch(error => {
                            console.error(error)
                            unresolved = true
                        })
                    }
                    // eslint-disable-next-line
                }
                await new Promise(resolve => setTimeout(resolve, timeStamps[2]))
                setDone(true)
                setResults(arr);
                setImageArr(imageArray)
                syncGeneration(r_res.data[0].fulfilled_at, arr.filter(item => item !== null), imageArray)
                while (unresolved) {
                    for (let j = 0; j < arr.length; j++) {
                        unresolved = false
                        if (arr[j].length === 3) {
                            unresolved = true
                            // eslint-disable-next-line
                            const promise = new Promise((resolve, reject) => {
                                unresolved = false
                                setTimeout(() => reject(new Error("Timeout")), timeStamps[3])
                                openai.createImage({ prompt: (arr[j][1]), n: 1, size: "1024x1024" })
                                    .then(response => resolve(response))
                                    .catch(error => reject(error))
                            })
                            // eslint-disable-next-line
                            promise.then(response => {
                                imageArray[j] = response
                                arr[j].push(response.data.data[0].url)
                                unresolved = false
                                // eslint-disable-next-line
                            }).catch(error => {
                                console.error(error)
                                unresolved = true
                            })
                        }
                    }
                    await new Promise(resolve => setTimeout(resolve, timeStamps[3]))
                    setResults(arr);
                    setDone(true)
                    syncGeneration(r_res.data[0].fulfilled_at, arr.filter(item => item !== null), imageArray)
                    setImageArr(imageArray)
                }
            }
            setResults(arr);
            setDone(true)
            setLoad(false)
        } catch (err) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            logError(err, userInput)
            setError('An error occured, please try again. If this keeps happening please contact support.');
            setLoad(false)
            setIsRunning(false);
        }

    }



    function requestJourney(generationState) {
        switch (generationState) {
            case 0:
                return
            case 1:
                return "GENERATING?"
            case 2:
                return "10GENERATIONDONE?"
            default:
                return;
        }
    }

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container component="main" style={matches ? styles.form : styles.formMobile}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styles.Modal}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Alert
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {modalMessage}
                            </Typography>
                        </Box>
                    </Modal>
                    <WelcomeTutorial />
                    <Box style={styles.generateDiv}>
                        <Typography style={matches ? styles.title : styles.titleMobile}>
                            Generate Calendar
                        </Typography>
                        {multipleProfiles && <Typography style={matches ? { ...styles.title, fontSize: 20, color: "black" } : { ...styles.titleMobile, fontSize: 15, color: "black" }}>
                            Active Profile: {selectedProfile.brandName}
                        </Typography>}
                        <form onSubmit={handleSubmit}>
                            <Grid container >
                                <Grid item sx={{ paddingTop: 5 }}>
                                    <Typography style={matches ? styles.intro : styles.introMobile}>
                                        What is your goal for the month?
                                    </Typography>
                                    <Typography style={matches ? styles.subText : styles.subTextMobile}>
                                        Describe your goal for this month and if you want to highlight any special occasion
                                    </Typography>
                                    <TextField
                                        label="Ex: encourage user generated content, promote my free trial."
                                        fullWidth
                                        variant="outlined"
                                        value={goal}
                                        onChange={(e) => setGoal(e.target.value)}
                                        name="goal"
                                        style={styles.div}
                                    />
                                </Grid>
                            </Grid>
                            {!shopify && <Grid container >
                                <Grid item sx={{ paddingTop: 5 }}>
                                    <Typography style={matches ? styles.intro : styles.introMobile}>
                                        What {selectedProfile.type}s do you want to promote?
                                    </Typography>
                                    <Typography variant="subtitle1" style={matches ? styles.subText : styles.subTextMobile}>
                                        Add the {selectedProfile.type}s that you would like to promote this month. Include the {selectedProfile.type}s description from the website and all the details that you would like me to include in your calendar:
                                    </Typography>
                                </Grid>
                                {items.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Grid container >
                                            <Grid item >
                                                <TextField
                                                    label={`name`}
                                                    fullWidth
                                                    variant="outlined"
                                                    value={item.name}
                                                    onChange={(e) => handleItemChange(e, index)}
                                                    name="name"
                                                    style={styles.radioGroup}
                                                />
                                            </Grid>
                                            <Grid item >
                                                <TextField
                                                    label={`description`}
                                                    fullWidth
                                                    variant="outlined"
                                                    value={item.description}
                                                    onChange={(e) => handleItemChange(e, index)}
                                                    name="description"
                                                    style={styles.radioGroup}
                                                />
                                            </Grid>
                                            <Grid item >
                                                <TextField
                                                    label={`link, dm, phone number`}
                                                    fullWidth
                                                    variant="outlined"
                                                    value={item.callToAction}
                                                    onChange={(e) => handleItemChange(e, index)}
                                                    name="callToAction"
                                                    style={styles.radioGroup}
                                                />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid item>
                                    {items.length < 10 ? <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleAddItem}
                                        style={styles.Button}
                                    >
                                        Add another one
                                    </Button> : null}
                                </Grid>
                            </Grid>}
                            <Grid item>
                                <Typography style={matches ? styles.intro : styles.introMobile}>
                                    Generate a content calendar for:
                                </Typography>
                                <FormControl
                                    fullWidth={true}
                                    margin='normal'
                                >
                                    <InputLabel id="platform-label">Social Media Platform</InputLabel>
                                    <Select
                                        labelId="platform-label"
                                        id="platform-select"
                                        value={selectedPlatform}
                                        onChange={handlePlatformChange}
                                        variant='filled'
                                    >
                                        {facebookIntegration && selectedProfile?.integrations?.some(integration => (
                                            integration.integration_type === "Facebook")) ? < MenuItem value="facebookConnection"><FacebookIcon className=' mr-2' />{selectedProfile?.integrations?.find(integration => (integration.integration_type === "Facebook"))?.integrated_page_name ? selectedProfile?.integrations?.find(integration => (integration.integration_type === "Facebook"))?.integrated_page_name : "Connected Facebook Page"}</MenuItem> :
                                            <MenuItem value="Facebook">Facebook</MenuItem>
                                        }
                                        {instagram && selectedProfile?.integrations?.some(integration => (
                                            integration.integration_type === "Instagram")) ? < MenuItem value="instagramConnection"><InstagramIcon className=' mr-2' />{selectedProfile?.integrations?.find(integration => (integration.integration_type === "Instagram"))?.integrated_page_name ? selectedProfile?.integrations?.find(integration => (integration.integration_type === "Instagram"))?.integrated_page_name : "Connected Instagram Page"}</MenuItem> :
                                            <MenuItem value="Instagram">Instagram</MenuItem>
                                        }
                                        <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                                        <MenuItem value="Twitter">Twitter</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {(((facebookIntegration && selectedProfile?.integrations?.some(integration => (integration.integration_type === "Facebook"))) || (instagram && selectedProfile?.integrations?.some(integration => (integration.integration_type === "Instagram")))) &&
                                (selectedPlatform === "facebookConnection" || selectedPlatform === "instagramConnection")) &&
                                <>
                                    <Typography style={matches ? styles.intro : styles.introMobile}>
                                        Optimize {selectedPlatform === "instagramConnection" ? "Instagram Account" : selectedPlatform === "facebookConnection" ? "Facebook Account" : "Calendar"} for:
                                    </Typography>
                                    <FormControl fullWidth={true} margin='normal'>
                                        <InputLabel id="platform-label">Metric</InputLabel>
                                        <Select
                                            labelId="platform-label"
                                            id="platform-select"
                                            value={facebookOptimize}
                                            onChange={handleFacebookOptimizeChange}
                                            variant='filled'
                                        >
                                            <MenuItem value="Likes">Likes</MenuItem>
                                            <MenuItem value="Comments">Comments</MenuItem>
                                            {facebookIntegration && selectedProfile?.integrations?.some(integration => (integration.integration_type === "Facebook")) && selectedPlatform === "facebookConnection" &&
                                                <MenuItem value="Shares">Shares</MenuItem>
                                            }
                                            {instagram && selectedProfile?.integrations?.some(integration => (integration.integration_type === "Instagram")) && selectedPlatform === "instagramConnection" &&
                                                <MenuItem value="Reach">Reach</MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                    {facebookOptimize === "Likes" &&
                                        <Typography>
                                            *Improve engagement and attract more users to like your content.
                                        </Typography>
                                    }
                                    {facebookOptimize === "Comments" &&
                                        <Typography>
                                            *Encourage discussions, gather feedback, and improve overall interaction on your profile.
                                        </Typography>
                                    }
                                    {facebookOptimize === "Shares" &&
                                        <Typography>
                                            *Expand the reach of your content and potentially attract new followers.
                                        </Typography>
                                    }
                                    {facebookOptimize === "Reach" &&
                                        <Typography>
                                            *Expand your audience and increase visibility among potential customers.
                                        </Typography>
                                    }
                                </>
                            }
                            <Grid item>
                                <Typography style={matches ? styles.intro : styles.introMobile}>
                                    Calendar starting date:
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar onChange={(newValue) => setStartingDate(newValue)} value={startingDate} />
                                </LocalizationProvider>
                            </Grid>
                            <Typography>{requestJourney(generationState)}</Typography>
                            {error && <Alert severity="error">{error}</Alert>}
                            <Typography style={styles.gensRemaining}>
                                You have {generationsLeft} generations remaining
                            </Typography>
                            {isRunning && <LinearProgress style={styles.progressBar} variant="determinate" value={progress} />}
                            <LoadingButton
                                loading={load}
                                type="submit"
                                endIcon={" "}
                                loadingPosition="end"
                                fullWidth
                                variant="contained"
                                style={styles.Button}
                            >
                                Generate
                            </LoadingButton>
                        </form>
                        {!load && done && calendarDownload &&
                            <Button style={{ ...styles.Button, marginLeft: 15 }} onClick={handleCalendarDownload}>
                                Download Calendar
                            </Button>}
                        {done && <div className='py-5 px-5 '>

                            {results.map((day, index) => (
                                <>
                                    <DayOutputBox day={day} index={index} results={results} setResults={setResults} fulfilledAt={fulfilledAt} userInput={userInput} setImageArr={setImageArr} imageArr={imageArr} openai={openai} englishPrompts={englishPrompts} setEnglishPrompts={setEnglishPrompts} prompts={prompts} />
                                </>
                            ))}
                        </div>}
                    </Box>
                </Container >
            )
            }
        </>
    );
}
