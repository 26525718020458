import './App.css';
import AuthContextProvider from './helpers/AuthContext';
import Navigation from './pages/Navigation/Navigation';
import ReactGA from "react-ga4";
import { useEffect } from "react"

function App() {

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

  useEffect (() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname})
  }, [])

  return (
    <AuthContextProvider>
      <Navigation />
    </AuthContextProvider>
  );
}

export default App;
