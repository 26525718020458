import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import styles from '../../Styles/GeneralStyles';
import { useAuth } from '../../helpers/AuthContext';

function FacebookPageSelect({ pages, platform }) {
  const { getSelectedFacebookPages, facebookConfigsVisible, linkSocialPage } = useAuth()
  const [selectedPage, setSelectedPage] = useState('');

  const [open, setOpen] = useState(true)

  const handlePageSelect = (page) => {
    setSelectedPage(page);
  };

  const handleConfirm = async () => {
    setOpen(false)
    await linkSocialPage(platform, selectedPage[0], selectedPage[1])
  };


  useEffect(() => {
    const handleFacebookPageId = async () => {
      if (pages !== undefined) {
        setOpen(true)
        if (pages.length === 1) {
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        }
      }
    }
    handleFacebookPageId()
    // eslint-disable-next-line
  }, [pages])

  return (
    <>
      {pages !== undefined && pages.length === 1 ? <Dialog open={open}>
        <DialogTitle style={{ ...styles.intro, fontSize: 30, margin: 0, padding: 20, textAlign: "center" }}>
          {pages[0][0]} has been linked!
        </DialogTitle>
      </Dialog>
        : pages !== undefined && pages.length !== 0 ?
          <Dialog open={open}>
            <DialogTitle style={{ ...styles.intro, fontSize: 30, margin: 0, padding: 20, textAlign: "center" }}>
              Select {platform === "Instagram" ? "an Instagram" : "a Facebook"} Page to Sync with Sococal
            </DialogTitle>
            <DialogContent >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {pages.map((page) => (
                  <div key={page} style={{ marginBottom: '10px' }}>
                    <Button
                      variant={selectedPage === page ? 'contained' : 'outlined'}
                      onClick={() => handlePageSelect(page)}
                      endIcon={selectedPage === page ? <CheckIcon /> : null}
                      style={{ ...styles.Button, minWidth: '200px', textTransform: 'none', margin: 5 }}
                    >
                      {page[0]}
                    </Button>
                  </div>
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  ...styles.Button, color: 'white', alignItems: 'right', margin: 0
                }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog> : null
      }
    </>
  );
}

export default FacebookPageSelect;
