import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../Auth/ForgotPassword/ForgotPassword";
import Login from "../Auth/Login/Login";
import ResetPassword from "../Auth/ResetPassword/ResetPassword";
import Signup from "../Auth/Signup/Signup";
import Generate from "../Generate/Generate";
import History from "../History/history";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import CreateUserProfile from "./CreateUserRoute";
import RedeemCode from "../ThirdPartyIntegrations/RedeemCode";
import Integrations from "../Integrations/Integrations";
import ProfileManagement from "../Profile/ProfileManagement";
import OnBoarding from "../Profile/OnBoarding";

export default function Navigation() {

    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/generate" element={<Generate />} />
                <Route path="/profile" element={<ProfileManagement />} />
                <Route path="/history" element={<History />} />
                <Route path="/integrations" element={<Integrations />} />
            </Route>
            <Route element={<CreateUserProfile />}>
                <Route path="/create-profile" element={<OnBoarding />} />
            </Route>
            <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
            </Route>
            <Route path="/lifetimeaccess" element={<RedeemCode />} />
            <Route path="*" element={<Login />} />
        </Routes>
    );
}