import React, { useState } from 'react'
import CategoryIcon from '@mui/icons-material/Category';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsAccessibilityRoundedIcon from '@mui/icons-material/SettingsAccessibilityRounded';
import BlueSococal from '../../assets/images/BlueSococal.png'
import { useAuth } from '../../helpers/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorBanner from '../../components/Error/ErrorBanner';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const OnBoarding = () => {
    const [step, setStep] = useState(-1)
    const [brandName, setBrandName] = useState("")
    const [brandDescription, setBrandDescription] = useState("")
    const [itemType, setItemType] = useState("product")
    const [idealCustomer, setIdealCustomer] = useState("")
    const [idealCustomerInterests, setIdealCustomerInterests] = useState("")
    const [website, setWebsite] = useState("")
    const [visualStyle, setVisualStyle] = useState("");
    const [toneOfVoice, setToneOfVoice] = useState("");
    const languages = ['English', 'Arabic', 'Spanish', 'Danish', 'French', 'Italian', 'Dutch', 'German', 'Portuguese', 'Swedish'];
    const [selectedLanguage, setSelectedLanguage] = useState("English")
    const [color1, setColor1] = useColor("hex", "#D9E3F0");
    const [color2, setColor2] = useColor("hex", "#FF0000");
    const [color3, setColor3] = useColor("hex", "#000000");
    const matches = useMediaQuery('(min-width:768px)');
    const { user, userProfile, setCreateProfile, setLogged, setSignUpState, signUpState, error, setError } = useAuth();
    const navigate = useNavigate();

    const handleLoginRedirect = async () => {
        setCreateProfile(false)
        setLogged(false)
        navigate('/login')
    }

    const handleNextStep = () => {
        if (
            (step == 0 && (brandName === "" || brandDescription === "" || website === "")) ||
            (step == 1 && (idealCustomer === "" || idealCustomerInterests === "")) ||
            (step == 3 && (visualStyle === "" || toneOfVoice === ""))
        ) {
            setError("Please fill all fields!")
            return "";
        } else {
            setError(undefined)
        }
        if (step < 5) {
            setStep(step + 1)
        }
    }
    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }


    const createProfile = async (e) => {
        e.preventDefault();
        const id = user.user.id ? user.user.id : '';
        const colors = [color1.hex, color2.hex, color3.hex];
        const userAttributes = {
            "id": id,
            "brandName": brandName,
            "brandDescription": brandDescription,
            "brandWebsite": website,
            "idealCustomer": idealCustomer,
            "idealCustomerInterests": idealCustomerInterests,
            "visualStyle": visualStyle,
            "brandColors": colors,
            "toneOfVoice": toneOfVoice,
            "brandNameChange": true,
            "language": selectedLanguage,
            "type": itemType
        };
        await userProfile(userAttributes);
        setStep(6);

    }



    function stepProgress() {
        return (
            <>
                <div className=' flex flex-row mt-2'>
                    <div className={` ${step === 0 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    <div className={` ${step === 1 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    <div className={` ${step === 2 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    <div className={` ${step === 3 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    <div className={` ${step === 4 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    <div className={` ${step === 5 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                </div>
            </>
        )
    }

    function stepActions() {
        return (
            <>
                <div className='flex justify-between'>
                    <button className={` ${step === 0 ? " scale-0" : "scale-100"} border-[1px] py-1 px-8 text-sm bg-white text-socoBlue font-poppins hover:scale-90 rounded-full transition-all`} onClick={handlePreviousStep}>Back</button>
                    {step !== 5 && <button className={`${step === 5 ? " scale-0 " : "scale-100"} border-[1px] py-1 px-8 text-sm bg-socoBlue text-white font-poppins hover:scale-110 rounded-full transition-all`} onClick={handleNextStep}>Next</button>}
                    {step === 5 &&
                        <button className={`border-[1px] py-2 shadow-inner shadow-white border-white px-8 text-sm bg-socoBlue font-black text-white font-poppins hover:scale-110 rounded-full transition-all`} onClick={createProfile}>Setup Profile</button>}        </div>
            </>
        )
    }


    function stepper() {
        switch (step) {
            case -1:
                return (
                    <>
                        <div className="bg-white text-white font-poppins p-12 rounded-lg select-none">
                            <div className="flex flex-col items-center">
                                <img src={BlueSococal} alt='Sococal Logo' className=' w-[200px]' />
                                <h1 className="text-4xl font-extrabold text-center my-4 text-socoBlue">Welcome to Sococal</h1>
                                <p className="text-xl mb-4 text-socoBlue">Take a minute to set up your account to enjoy our experience!</p>
                                <button
                                    onClick={handleNextStep}
                                    className=" transition-all text-white bg-socoBlue hover:scale-110 px-4 py-2 rounded-md font-bold cursor-pointer"
                                >
                                    Continue Account Setup
                                </button>
                            </div>
                        </div>
                    </>

                )
            case 0:
                return (
                    <>
                        <h1 className='text-3xl font-bold font-poppins text-center'>Brand Name</h1>
                        <p className="text-center font-poppins text-gray-600 mb-3">Enter your brand's name:</p>
                        <input
                            value={brandName}
                            onChange={(e) => setBrandName(e.target.value)}
                            className='p-5 bg-socoBlue font-poppins text-center bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[60%] h-[10%]'
                            placeholder="SoCoFit"
                        />

                        <h1 className='text-3xl font-bold font-poppins text-center'>Brand Description</h1>
                        <p className="text-center font-poppins text-gray-600 mb-3">Provide a brief description of your brand:</p>
                        <textarea
                            value={brandDescription}
                            onChange={(e) => setBrandDescription(e.target.value)}
                            className="p-3 bg-socoBlue text-center font-poppins bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[80%] resize-none overflow-hidden"
                            placeholder="SoCoFit is a health brand that promotes an active lifestyle and offers various nutrition plans to help customers achieve their goals."
                        />

                        <h1 className='text-3xl font-bold font-poppins text-center'>Website</h1>
                        <p className="text-center font-poppins text-gray-600 mb-3">Provide your brand's website:</p>
                        <input
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            className='p-5 bg-socoBlue font-poppins text-center bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[60%] h-[10%]'
                            placeholder="https://www.socofit.com"
                        />
                    </>

                )
            case 1:
                return (
                    <>
                        <h1 className='text-3xl font-bold font-poppins text-center'>Ideal Customer</h1>
                        <p className="text-center font-[100] font-poppins text-gray-600 mb-3">Brief description of your ideal customer:</p>
                        <input
                            value={idealCustomer}
                            onChange={(e) => setIdealCustomer(e.target.value)}
                            className='p-5 bg-socoBlue font-poppins text-center bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[60%] h-[10%]'
                            placeholder="Women aged 25-40 interested in fitness."
                        />

                        <h1 className='text-3xl font-bold font-poppins text-center'>Ideal Customer Interests</h1>
                        <p className="text-center font-[100] font-poppins text-gray-600 mb-3">Interests and preferences of your ideal customers:</p>
                        <textarea
                            value={idealCustomerInterests}
                            onChange={(e) => setIdealCustomerInterests(e.target.value)}
                            className="p-5 bg-socoBlue text-center font-poppins bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[70%] resize-none overflow-hidden"
                            placeholder="They like yoga, CrossFit, and healthy eating."
                        />
                    </>
                )
            case 2:
                return (
                    <>
                        <h1 className='text-4xl text-socoBlue font-extrabold text-center font-poppins mb-10'>What are you promoting?</h1>
                        <div className='flex flex-row'>
                            <div onClick={(e) => setItemType("product")} className={` ${itemType === "product" ? "bg-socoBlue border-white scale-110" : "bg-white hover:scale-105 border-socoBlue border-opacity-50"} p-2 transition-all cursor-pointer flex flex-col items-center mr-3 border-2 lg:w-[230px] w-[130px] h-full rounded-lg`}>
                                <CategoryIcon className='scale-50 lg:scale-100' sx={{ fontSize: 150, color: itemType === "product" ? "#FFFFFF" : "#013b82", opacity: itemType === "product" ? "100%" : "50%" }} />
                                <h3 className={`${itemType === "product" ? "text-white" : "text-socoBlue text-opacity-50"} text-2xl font-black font-poppins`}>Products</h3>
                            </div>
                            <div onClick={(e) => setItemType("service")} className={` ${itemType === "service" ? "bg-socoBlue border-white scale-110" : "bg-white hover:scale-105 border-socoBlue border-opacity-50"} p-2 transition-all cursor-pointer flex flex-col items-center ml-3 border-2 lg:w-[230px] w-[130px] lg:h-full rounded-lg`}>
                                <MiscellaneousServicesIcon className='scale-50 lg:scale-100' sx={{ fontSize: 150, color: itemType === "service" ? "#FFFFFF" : "#013b82", opacity: itemType === "service" ? "100%" : "50%" }} />
                                <h3 className={`${itemType === "service" ? "text-white" : "text-socoBlue text-opacity-50"} text-2xl font-black font-poppins`}>Services</h3>
                            </div>
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        <h1 className='text-3xl font-bold font-poppins text-center'>Visual Style</h1>
                        <p className="text-center font-poppins text-gray-600 mb-3">Describe the visual style of your brand:</p>
                        <input
                            value={visualStyle}
                            onChange={(e) => setVisualStyle(e.target.value)}
                            className='p-5 bg-socoBlue font-poppins text-center bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[60%] h-[10%]'
                            placeholder="Modern, Minimalist with vibrant colors."
                        />

                        <h1 className='text-3xl font-bold font-poppins text-center'>Tone of Voice</h1>
                        <p className="text-center font-poppins text-gray-600 mb-3">Describe the tone of voice for your brand:</p>
                        <input
                            value={toneOfVoice}
                            onChange={(e) => setToneOfVoice(e.target.value)}
                            className='p-5 bg-socoBlue font-poppins text-center bg-opacity-10 border-socoBlue appearance-none border-[1px] rounded-2xl my-3 w-[60%] h-[10%]'
                            placeholder="Friendly, professional, and informative."
                        />
                    </>
                )
            case 4:
                return (
                    <>
                        <h1 className='text-3xl font-bold font-poppins text-center'>Brand Colors</h1>
                        <p className="text-center font-poppins text-gray-600">Select the colors of your brand:</p>
                        <div className='flex md:flex-row flex-col p-10'>
                            <div className=' m-2'>
                                <ColorPicker width={230} height={matches ? 150 : 50} color={color1} onChange={setColor1} hideRGB hideHSV />
                            </div>
                            <div className=' m-2'>
                                <ColorPicker width={230} height={matches ? 150 : 50} color={color2} onChange={setColor2} hideRGB hideHSV />
                            </div>
                            <div className=' m-2'>
                                <ColorPicker width={230} height={matches ? 150 : 50} color={color3} onChange={setColor3} hideRGB hideHSV />
                            </div>
                        </div>
                    </>
                )

            case 5:
                return (
                    <>
                        <h1 className='text-3xl font-bold font-poppins text-center'>Language</h1>
                        <p className="text-center font-poppins text-gray-600 mb-3">Select your preferred lanaguage:</p>
                        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                            {languages.map((language, index) => (
                                <div onClick={(e) => setSelectedLanguage(language)} key={index} className={`${language === selectedLanguage ? "scale-105 bg-socoBlue text-white" : "bg-socoBlue bg-opacity-10 cursor-pointer"} select-none p-4 transition-all border-socoBlue border-[1px] rounded-2xl font-poppins text-center`}>
                                    {language}
                                </div>
                            ))}
                        </div>
                    </>
                )
            case 6:
                return (
                    <>
                        <div className="bg-white text-white font-poppins p-12 rounded-lg select-none">
                            <div className="flex flex-col items-center">
                                <MarkEmailReadIcon className='scale-50 lg:scale-100' sx={{ fontSize: 150, color: "#103b82", opacity: itemType === "products" ? "100%" : "50%" }} />
                                <h1 className="text-4xl font-extrabold text-center my-4 text-socoBlue">You are all set!</h1>
                                <p className="text-xl mb-4 text-socoBlue">Please Verify your Account before Logging In!</p>
                                <button
                                    onClick={handleLoginRedirect}
                                    className=" transition-all text-white bg-socoBlue hover:scale-110 px-4 py-2 rounded-md font-bold cursor-pointer"
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </>
                )
        }
    }

    return (
        <>
            <div className={`${!matches && step === 4 && ""} flex justify-center items-center min-h-[90vh]`}>
                <div className="w-full mt-24 lg:mt-0 px-2 lg:px-10 mx-2 h-fit lg:w-[800px] shadow-2xl rounded-3xl flex flex-col">
                    {(step >= 0 && step < 6) && <div className="flex flex-col items-center justify-center mb-6">
                        {stepProgress()}
                    </div>}
                    <div className="flex flex-col items-center justify-center flex-1">
                        {stepper()}
                    </div>
                    {error && <div className="p-5 mt-5 self-center">
                        <ErrorBanner />
                    </div>}
                    {(step >= 0 && step < 6) && <div className="p-5 mt-5">
                        {stepActions()}
                    </div>}
                </div>
            </div>
        </>
    )
}

export default OnBoarding