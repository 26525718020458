import axios from 'axios';

const mode = process.env.NODE_ENV;

const baseURL =
    mode === "development" ?
        process.env.REACT_APP_DEV_SERVER
        : mode === "test" ?
            process.env.REACT_APP_TEST_SERVER
            : mode === "production" ?
                process.env.REACT_APP_PROD_SERVER
                : ''


const api = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    },
});

export default api;
