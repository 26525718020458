import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import styles from '../../Styles/GeneralStyles';
import { useAuth } from '../../helpers/AuthContext';




export default function History() {

    const { getHistory, user, downloadCalendar, calendarDownload, mobile, selectedProfile } = useAuth();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery('(min-width:600px)');
    const [maximizedIndex, setMaximizedIndex] = useState(null);

    const toggleMaximize = (index) => {
        if (index === maximizedIndex) {
            setMaximizedIndex(null);
        } else {
            setMaximizedIndex(index);
        }
    };

    const DateExtractor = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const handleCalendarDownload = async (gen) => {
        const res = await downloadCalendar(gen.result, DateExtractor(gen.fulfilled_at))
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${DateExtractor(gen.fulfilled_at)}.csv`);
        document.body.appendChild(link);
        link.click();

    }

    useEffect(() => {
        const fetchHistory = async () => {
            await user;
            const res = await getHistory(user.user.id)
            const flippedRes = res.reverse();
            console.log(flippedRes)
            setHistory(flippedRes)
            setLoading(false);
        }
        fetchHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box style={styles.Background}>
                    <Container component="main" style={styles.form}>
                        <Box style={styles.div}>
                            {history.length === 0 ? (
                                <Typography variant="subtitle1" style={styles.mainText}>
                                    You still have no calendars!
                                </Typography>
                            ) : (
                                history.map((gen, i) => (
                                    <Box key={i} style={{ marginTop: matches ? "20px" : "50px", padding: 30 }}>
                                        <Grid container spacing={2}>
                                            <Typography variant="subtitle1" style={matches ? styles.historyHeader : styles.historyHeaderMobile}>
                                                {gen.result[0][2]} Calendar
                                            </Typography>
                                            {gen.result.slice(0, i === maximizedIndex ? gen.result.length : 1).map((day, j) => (
                                                <div className=' transition-transform align text-left py-5 font-poppins px-10 rounded-3xl flex flex-col mb-9 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] scale-[1.02] hover:scale-[1.04] ease-linear duration-200 bg-white lg:flex-row'>
                                                    <div className={`align-middle flex-shrink-1 `}>
                                                        <div className='flex flex-row justify-between'>
                                                            <h3 className='text-lg font-black text-black text-center pb-5'>
                                                                Day {j + 1} - {day[2]}
                                                            </h3>
                                                        </div>
                                                        <div className='flex text-2xl font-black text-socoBlue transition-all rounded-full w-fit group'>
                                                            <h3 className='text-2xl font-black text-socoBlue '>
                                                                Visual Idea :
                                                            </h3>
                                                        </div>
                                                        <p style={{ direction: selectedProfile.language === "Arabic" ? 'rtl' : "" }} className='pl-2'>
                                                            {day[1]}
                                                        </p>
                                                        <h3 className='text-2xl font-black text-socoBlue '>
                                                            Caption :
                                                        </h3>
                                                        <p style={{ direction: selectedProfile.language === "Arabic" ? 'rtl' : "" }} className='pl-2'>
                                                            {day[0]}
                                                        </p>
                                                    </div>
                                                    {day[3] && <div>
                                                        <div className='flex-shrink-0 flex justify-center py-5 lg:p-10 group z-20'>
                                                            <button className='relative'>
                                                                <img className='transition-all min-w-[200px] min-h-[200px] lg:min-w-[300px] lg:min-h-[300px] rounded-3xl shadow-xl' src={day[3]} alt='Generated Image' />
                                                            </button>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </Grid>
                                        <Button style={styles.Button} onClick={() => toggleMaximize(i)}>
                                            {i === maximizedIndex ? "Minimize" : "Show full Calendar"}
                                        </Button>
                                        {calendarDownload && <Button style={{ ...styles.Button, marginLeft: 15 }} onClick={() => handleCalendarDownload(gen)}>
                                            Download Calendar
                                        </Button>}
                                    </Box>
                                ))
                            )}
                        </Box>
                    </Container>
                </Box>
            )}
        </>
    );
}
