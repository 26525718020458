import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../Styles/BellNotificationsSyles';
import { useAuth } from '../helpers/AuthContext';

const StyledBox = styled(Box)({
  maxWidth: 400,
  width: 300,
  maxHeight: 200,
  overflowY: 'auto',
  padding: '5px',
  boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
  borderRadius: '3px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
  },
});

const NotificationBox = styled(Box)({
  marginBottom: '3px',
  padding: '5px',
  backgroundColor: '#F8F8F8',
  borderRadius: '5px',
});

const DismissButton = styled(IconButton)({
  position: 'relative',
  float: 'right',
  color: '#888',
  '&:hover': {
    color: '#013b82',
  },
});



function NotificationsBox({ isOpen, onClose }) {

  const { dismissNotifications, notifications, setNotifications } = useAuth()

  const dismissNotification = (notification, index) => {
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(index, 1);
    setNotifications(updatedNotifications);
    dismissNotifications(notification.id);
  }

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {notifications !== false ? <StyledBox>
        {notifications.length > 0 ? notifications.map((notification, index) => (
          <NotificationBox color={'#013b82'} key={index}>
            <DismissButton onClick={() => dismissNotification(notification, index)}>
              <CloseIcon />
            </DismissButton>
            <Typography color={'black'} style={styles.title} variant="subtitle1">
              {notification.title}
            </Typography>
            {notification.redirection ?
              <a href={notification.redirect_url} rel="noreferrer" target='_blank' style={{ textDecoration: 'none' }}>
                <Typography color={'#013b82'} style={styles.message} variant="body1">
                  {notification.messages}
                </Typography>
              </a> :
              <Typography color={'#013b82'} style={styles.message} variant="body1">
                {notification.messages}
              </Typography>
            }
          </NotificationBox>
        )) : <NotificationBox color={'#013b82'}>
          <Typography color={'#013b82'} style={styles.noNotifications}>
            There are no new notifications to show!
          </Typography>
        </NotificationBox>}
      </StyledBox> : null}
    </>
  );
}

export default NotificationsBox;
