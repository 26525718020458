import React, { useState } from 'react'
import TutorialDesktop from '../../assets/Tutorial/TutorialDesktop.png'
import TutorialMobile from '../../assets/Tutorial/TutorialMobile.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '../../helpers/AuthContext';


const WelcomeTutorial = () => {
    const [step, setStep] = useState(0)
    const matches = useMediaQuery('(min-width:500px)');
    const { showTutorial, setShowTutorial } = useAuth()


    const handleNextStep = () => {
        if (step < 0) {
            setStep(step + 1)
        }
    }
    const handlePreviousStep = () => {
        if (step > 0) {
            setStep(step - 1)
        }
    }

    const handleClose = () => {
        setShowTutorial(false)
    }



    function stepProgress() {
        return (
            <>
                <div className=' flex flex-row mt-2'>
                    <div className={` ${step === 0 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    {/* <div className={` ${step === 1 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} />
                    <div className={` ${step === 2 ? "bg-socoBlue scale-110" : "bg-white border-[0.5px] border-socoBlue"} bg-socoBlue w-8 h-2 m-1 rounded-full transition-all`} /> */}
                </div>
            </>
        )
    }

    function stepActions() {
        return (
            <>
                <div className='flex justify-between'>
                    {/* <button className={` ${step === 0 ? " scale-0" : "scale-100"} border-[1px] py-1 px-8 text-sm bg-white text-socoBlue font-poppins hover:scale-90 rounded-full transition-all`} onClick={handlePreviousStep}>Back</button> */}
                    {step !== 2 && <button className={`${step === 2 ? " scale-0" : "scale-100"} border-[1px] py-1 px-8 text-sm bg-socoBlue text-white font-poppins hover:scale-110 rounded-full transition-all`} onClick={handleClose}>Let's Go!</button>}
                    {step === 2 &&
                        <button className={`border-[1px] py-2 shadow-inner shadow-white border-white px-8 text-sm bg-socoBlue font-black text-white font-poppins hover:scale-110 rounded-full transition-all`} onClick={handleNextStep}>Finish!</button>}
                </div>
            </>
        )
    }


    function stepper() {
        switch (step) {
            case 0:
                return (
                    <>
                        <div className=' flex flex-col'>
                            <img src={matches ? TutorialDesktop : TutorialMobile} alt="Tutorial" className="mx-auto mb-4 rounded-md shadow-lg lg:w-full w-3/4" />
                            <p className=' font-poppins font-bold lg:text-2xl text-lg'>Welcome to Sococal. Explore and enjoy your journey through our website!</p>
                        </div>
                    </>

                )
            case 1:
                return (
                    <>
                        <div className=' flex flex-col'>
                            <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="Step 3" className="mx-auto mb-4" />
                            <p>This is a description for the image!</p>
                        </div>
                    </>
                )
            case 2:
                return (
                    <>
                        <div className=' flex flex-col'>
                            <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="Step 3" className="mx-auto mb-4" />
                            <p>This is a description for the image!</p>
                        </div>
                    </>
                )
        }
    }

    return (
        <>
            {showTutorial &&    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-10 transition-all overflow-visible'>
                <div className="w-full mt-24 lg:mt-0 px-2 lg:px-10 mx-2 h-fit lg:w-[800px] shadow-2xl rounded-3xl flex flex-col bg-white">
                    {step !== -1 && <div className="flex flex-col items-center justify-center mb-6">
                        {stepProgress()}
                    </div>}
                    <div className="flex flex-col items-center justify-center flex-1">
                        {stepper()}
                    </div>
                    {step !== -1 && <div className="p-5 mt-5">
                        {stepActions()}
                    </div>}
                </div>
            </div>}
        </>
    )
}

export default WelcomeTutorial