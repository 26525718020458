import React, { useState } from 'react'
import LoginWithFacebook from '../../components/Facebook/LoginWithFacebook'
import { useAuth } from '../../helpers/AuthContext'
import { Select, MenuItem } from '@mui/material'
import { ReactSocialMediaIcons } from 'react-social-media-icons';
import FacebookIcon from '@mui/icons-material/Facebook';
import ErrorBanner from '../../components/Error/ErrorBanner';

const Integrations = () => {

    const { mobile, selectedProfile, profiles, setSelectedProfile, multipleProfiles, selectProfile, user } = useAuth()

    const handleProfileChange = (event) => {
        setSelectedProfile(profiles[event.target.value]);
        selectProfile(profiles[event.target.value])
    };
    return (
        <div className='  pt-24 items-center justify-center flex flex-col font-poppins '>
            <div className={` rounded-2xl bg-white text-black font-black p-5 ${mobile ? "w-11/12" : "w-[1000px]"}  shadow-lg`}>
                <div className='flex flex-row justify-between pb-8'>
                    <h2 className='  text-[30px]'>
                        Integrations
                    </h2>
                    {multipleProfiles && <Select
                        className=' min-w-[100px] h-9 shadow-sm'
                        labelId="profile-select-label"
                        onChange={handleProfileChange}
                        value={profiles.findIndex(profile => (
                            JSON.stringify(profile) === JSON.stringify(selectedProfile)
                        ))}
                        margin="dense"
                    >
                        {profiles.map((profile, index) => (
                            <MenuItem key={index} value={index}>
                                {profile.brandName}
                            </MenuItem>
                        ))}
                    </Select>}
                </div>
                <div className='flex justify-center'>
                    <ErrorBanner />
                </div>
                <div className='flex flex-col font-[550]'>
                    <div className="flex flex-col sm:flex-row align-middle justify-center items-stretch">
                        <div className='flex-grow flex flex-col justify-between shadow-lg rounded p-8 border-2 border-opacity-10 border-gray'>
                            <div className='pointer-events-none flex flex-row justify-between text-2xl font-extrabold'>
                                <FacebookIcon style={{ fontSize: 75, color: "rgba(24, 119, 242,1)" }} />
                                <h4>Facebook</h4>
                            </div>
                            <p className='py-3'>Optimize your content calendar for likes, comments, and shares. Connect Facebook to stay ahead of the game with AI-generated calendars tailored for your audience.</p>
                            <p className=' pb-5 font-extrabold text-[12px]'>*Admin access required for the Facebook page.</p>
                            <div className='flex justify-end'>
                                <LoginWithFacebook platform={"Facebook"} />
                            </div>
                        </div>
                        <div className='flex-grow flex flex-col justify-between shadow-lg rounded p-8 border-2 border-opacity-10 border-gray'>
                            <div className='pointer-events-none flex flex-row justify-between text-2xl font-extrabold'>
                                <ReactSocialMediaIcons borderColor="rgba(255, 255, 255,1)" backgroundColor="rgba(225, 48, 108,1)" iconColor="rgba(255, 255, 255,1)" icon="instagram" size="60" />
                                <h4>Instagram</h4>
                            </div>
                            <p className='py-3'>By fetching likes, comments, and reach for each post, connect Instagram to stay ahead of the game with AI-generated calendars tailored for your audience and optimized for your brand.</p>
                            <p className=' pb-5 font-extrabold text-[12px]'>*Admin access and connected Instagram business account required for the Facebook page.</p>
                            <div className='flex justify-end'>
                                <LoginWithFacebook platform={"Instagram"} scopes={"public_profile, email, pages_show_list, instagram_basic, instagram_manage_insights"} placeholder={"Connect Instagram"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Integrations