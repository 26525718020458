import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import styles from '../../Styles/GeneralStyles';
import { useAuth } from '../../helpers/AuthContext';


const ContactUsDialog = ({ isOpen, onClose }) => {

    const [option, setOption] = useState('feedback');
    const [subject, setSubject] = useState();
    const [details, setDetails] = useState();
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [load, setLoad] = useState(false)

    const { sendSupportEmail, supportOpen, handleSupportClose } = useAuth()


    const handleClose = () => {
        setError()
        setSuccess()
        onClose()
    };

    const handleOptionChange = (event) => {
        setOption(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            setLoad(true)
            setError()
            setSuccess()
            if (subject === '' || details === undefined) {
                setError("Please fill out the form before sending")
                setLoad(false)
                return ""
            }
            await sendSupportEmail(option, subject, details)
            setSuccess(true)
            setLoad(false)
            onClose()
        } catch (error) {
            setError("There was an error recieving your message!")
            setLoad(false)
        }
    };

    return (
        <>

            <Dialog open={supportOpen} onClose={handleSupportClose}>
                <DialogTitle style={styles.intro}>Support</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">Select Type:</FormLabel>
                        <RadioGroup name="option" value={option} onChange={handleOptionChange}>
                            <FormControlLabel value="feedback" control={<Radio />} label="Feedback" />
                            <FormControlLabel value="support" control={<Radio />} label="Customer Support" />
                            <FormControlLabel value="feature" control={<Radio />} label="Feature Request" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        label="Subject"
                        fullWidth
                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Details"
                        fullWidth
                        multiline
                        rows={4}
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        margin="normal"
                    />
                    {error && (
                        <Alert severity="error" style={styles.alert}>
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" style={styles.alert}>
                            Your message has been recieved successfully!
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSupportClose} color="primary">
                        Cancel
                    </Button>
                    <LoadingButton loading={load} onClick={handleSubmit} variant="contained" style={styles.Button}>
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ContactUsDialog;
