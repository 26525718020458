const styles = {
    BellIcon: {
        position: 'fixed',
        top: '100px',
        right: '30px',
        cursor: 'pointer',
    },
    icon: {
        fontSize: '40px',
    },
    window: {
        display: 'none',
        position: 'fixed',
        zindex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    content: {
        backgroundcolor: 'white',
        position: 'absolute',
    },
    title: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
        fontSize: '18px',
        color: 'black',
        margin: '0px 0px',
        textAlign: 'left',
    },
    message: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '400',
        fontSize: '12px',
        color: '#013b82',
        margin: '0px 0px',
        textAlign: 'left',
    },
    noNotifications: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '400',
        fontSize: '15px',
        color: '#000000',
        margin: '0px 0px',
        textAlign: 'center',
        padding: "5px 0px 5px 0px"
    }
};
export default styles;