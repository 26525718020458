import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginWithSocial } from './supabase/supabase';
import useFetch from './useFetch/useFetch';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileHeader from '../components/Headers/MobileHeader';
import Header from '../components/Headers/Header';


const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const [logged, setLogged] = useState(false);
    const [user, setUser] = useState();
    const [createProfile, setCreateProfile] = useState(false);
    const [signUpState, setSignUpState] = useState(false)
    const { fetchData } = useFetch();
    const [generationsLeft, setGenerationsLeft] = useState()
    const navigate = useNavigate();
    const [shopify, setShopify] = useState(false)
    const [loads, setLoads] = useState(false)
    const [historyVisible, setHistoryVisible] = useState(false)
    const [subscribeButtonVisible, setSubscribeButtonVisible] = useState(false)
    const [shopifyButtonVisible, setShopifyButtonVisible] = useState(false)
    const [facebookConfigsVisible, setFacebookConfigsVisible] = useState(false)
    const [shopifyConfigsVisible, setShopifyConfigsVisible] = useState(false)
    const [refreshCaptions, setRefreshCaptions] = useState(false)
    const [refreshImages, setRefreshImages] = useState(false)
    const [generateImages, setGenerateImages] = useState(false)
    const [trial, setTrial] = useState(false)
    const [profileLock, setProfileLock] = useState(false)
    const [codeRedeem, setCodeRedeem] = useState(false)
    const [notifications, setNotifications] = useState(false)
    const [refreshCaptionsCount, setRefreshCaptionsCount] = useState(false)
    const [refreshImagesCount, setRefreshImagesCount] = useState(false)
    const [facebookLink, setFacebookLink] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState()
    const [profiles, setProfiles] = useState()
    const [multipleProfiles, setMultipleProfiles] = useState()
    const mobile = !useMediaQuery('(min-width:1000px)');
    const [facebookIntegration, setFacebookIntegration] = useState()
    const [calendarDownload, setCalendarDownload] = useState()
    const [profileCount, setProfileCount] = useState()
    const [refreshDay, setRefreshDay] = useState(false)
    const [refreshDayCount, setRefreshDayCount] = useState(false)
    const [editCalendar, setEditCalendar] = useState(false)
    const [instagram, setInstagram] = useState(false)
    const [error, setError] = useState(undefined)
    const [dropDownNavigation, setDropDownNavigation] = useState(false)
    const [supportOpen, setSupportOpen] = useState(false)
    const [showTutorial, setShowTutorial] = useState(false)

    useEffect(() => {
        const retrieve_session = async () => {
            try {
                setLoads(true)
                const token = localStorage.getItem('userToken');

                if (token && !logged) {
                    const res = await retrieveSession()
                    setUser(res);
                    setLogged(true);
                    setGenerationsLeft(res.generationsLeft)
                    setLoads(false)
                }
                const isPathnameEmpty = window.location.pathname === '/';
                if (isPathnameEmpty) {
                    navigate('/generate')
                }
                setLoads(false)
            } catch (error) {
                await logout();
                console.log(error)
                setLoads(false)
            }
        }
        retrieve_session();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logged, user, generationsLeft]);

    const handleDropDownToggle = () => {
        setDropDownNavigation(!dropDownNavigation)
    }

    const handleSupportClose = () => {
        setSupportOpen(!supportOpen)
    }

    const login = async (email, password) => {
        const loginParams = {
            email: email,
            password: password
        };
        try {
            const options = {
                method: 'POST',
                body: loginParams,
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetchData('/signin', options.method, options.body, options.headers);
            localStorage.setItem('userToken', response.access_token);
            setLogged(true);
            setUser(response);
            setGenerationsLeft(response.generationsLeft)
            setStates(response)
            const check = response.userProfile;
            if (check.length === 0) {
                setCreateProfile(true);
                navigate('/create-profile');
            } else {
                setCreateProfile(false);
                navigate('/generate');
            }
        }
        catch (error) {
            throw error;
        }

    }

    const checkNewUser = async (user) => {
        try {
            const givenDateString = user.session ? user.session.user.confirmed_at : user.user.confirmed_at;
            const currentDate = new Date();

            const givenDate = new Date(givenDateString);

            const timeDifference = currentDate.getTime() - givenDate.getTime();

            const oneMinutesInMilliseconds = 1 * 60 * 1000;
            const isWithinOneMinute = Math.abs(timeDifference) <= oneMinutesInMilliseconds;
            if (isWithinOneMinute) {
                setShowTutorial(true)
            }
        } catch (error) {
            console.error(error)
        }

    }

    const setStates = async (user) => {
        setHistoryVisible(user.subscription_metric.history ? true : false)
        setSubscribeButtonVisible(user.subscription_metric.trial)
        setShopifyButtonVisible((user.subscription_metric.shopify && user.active_Integrations.shopify) ? true : false)
        setFacebookConfigsVisible((user.subscription_metric.facebook && (user.active_Integrations.facebook === 0 || user.active_Integrations.facebook === true)) ? true : false)
        setShopifyConfigsVisible((user.subscription_metric.shopify && user.active_Integrations.shopify) ? true : false)
        setRefreshCaptions(user.subscription_metric.refreshCaptions)
        setRefreshImages(user.subscription_metric.refreshImages)
        setGenerateImages(user.subscription_metric.images)
        setShopify(user.active_Integrations.shopify && user.subscription_metric.shopify)
        setTrial(user.subscription_metric.trial)
        setProfileLock(user.subscription_metric.profileLock)
        setProfileCount(user.subscription_metric.profileCount)
        setCodeRedeem(user.subscription_metric.codeRedeem)
        setRefreshCaptionsCount(user.subscription_metric.refreshCaptionsCount)
        setRefreshImagesCount(user.subscription_metric.refreshImagesCount)
        setFacebookLink((user.subscription_metric.facebook && user.active_Integrations.facebook !== 0) ? true : false)
        setMultipleProfiles(user.subscription_metric.multipleAccounts)
        setFacebookIntegration(user.subscription_metric.facebook)
        setCalendarDownload(user.subscription_metric.calendarDownload)
        const profile = user.agency_profiles.find((profile) => profile.selected)
        if (profile) {
            setSelectedProfile(profile)
            const allProfiles = [profile, user.userProfile[0]]
            if (user.agency_profiles.length > 1 && user.subscription_metric.profileCount > 2) {
                for (let i = 0; i < user.agency_profiles.length; i++) {
                    if (profile.id !== user.agency_profiles[i].id) {
                        allProfiles.push(user.agency_profiles[i]);
                    }
                    if (allProfiles.length === user.subscription_metric.profileCount) {
                        break;
                    }
                }
            }
            setProfiles(allProfiles)
        } else {
            setSelectedProfile(user.userProfile[0])
            const allProfiles = [user.userProfile[0]]
            if (user.agency_profiles.length > 0) {
                const count = Math.min((user.subscription_metric.profileCount - 1), user.agency_profiles.length);
                for (let i = 0; i < count; i++) {
                    allProfiles.push(user.agency_profiles[i]);
                }
            }
            setProfiles(allProfiles)
        }

        if (notifications === false) {
            const notis = await getNotifications(user.user.id)
            setNotifications(notis)
        }
        setRefreshDay(user.subscription_metric.refreshDay)
        setRefreshDayCount(user.subscription_metric.refreshDayCount)
        setEditCalendar(user.subscription_metric.editCalendar)
        setInstagram(user.subscription_metric.instagram)
        checkNewUser(user)
    }

    const checkFacebook = async () => {
        const fbCheck = localStorage.getItem('sb-cioqxvnnvlzlquclefqj-auth-token');
        if (fbCheck) {
            const obj = JSON.parse(fbCheck)
            return obj.provider_token
        }
        return "none"
    };

    const loginWithSocial = async (provider) => {
        try {
            await LoginWithSocial(provider);

        } catch (error) {
            console.log(error)
        }
    };

    const logout = async () => {
        setLogged(false);
        setUser(undefined);
        setCreateProfile(false);
        localStorage.removeItem('userToken');
        localStorage.removeItem('sb-cioqxvnnvlzlquclefqj-auth-token');
    }

    const signup = async (firstName, lastName, email, password) => {
        const signupParams = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password
        }
        const options = {
            method: 'post',
            body: signupParams,
            headers: { 'content-Type': 'application/json' }
        }
        try {
            const response = await fetchData('/register', options.method, options.body, options.headers)
            let temp = '';
            if (response && response.response && response.response.status) {
                temp = response.response.status;
            }
            if (temp === 400) {
                return "err"
            }
            else {
                setUser(response);
                setCreateProfile(true);
                setLogged(true)
                navigate('/create-profile');
            }
        } catch (error) {
            throw error;
        }
    }

    const retrieveSession = async (access_token) => {
        try {
            if (signUpState) {
                await new Promise((resolve) => setTimeout(resolve, 10000));
            }
            const token = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : window.location.hash.split('&')[0].split('=')[1];
            if (token) {
                const options = {
                    method: 'POST',
                    body: { userToken: token },
                    headers: { 'Content-Type': 'text/plain' },
                };
                const response = await fetchData('/retrieve_session', options.method, options.body, options.headers);
                const check = response.userProfile;
                if (check.length === 0) {
                    setCreateProfile(true);
                    navigate('/create-profile');
                }
                setGenerationsLeft(response.generationsLeft)
                await setStates(response)
                setUser(response)
                return response;
            }
        } catch (error) {
            throw error;
        }
    }


    const registerFacebook = async () => {
        try {
            const facebookUser = localStorage.getItem('sb-cioqxvnnvlzlquclefqj-auth-token');
            const options = {
                method: 'POST',
                body: facebookUser,
                headers: { 'content-Type ': 'application/json' },
            };
            const response = await fetchData('/registerFacebook', options.method, options.body, options.headers);
            setCreateProfile(true);
            setGenerationsLeft(response.generationsLeft)
            setUser(response)
            navigate('/create-profile');
            return response
        } catch (error) {
            throw error;
        }
    }

    const postUserInfo = async (attributes) => {
        const options = {
            method: 'POST',
            body: attributes,
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetchData('/addPromptsSupabase', options.method, options.body, options.headers);
        return response;
    }


    const userProfile = async (profileAttributes) => {
        const options = {
            method: 'POST',
            body: { ...profileAttributes, profileId: selectedProfile?.uuid ? selectedProfile.id : 0 },
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData('/update_user_info', options.method, options.body, options.headers);
        retrieveSession();
        return response;
    }

    const getGenerationPrompts = async (userInput) => {
        const options = {
            method: 'POST',
            body: { ...userInput, profile: selectedProfile, fbToken: "none" },
            headers: { 'content-Type ': 'application/json' },
        };
        const response = await fetchData('/buildGenerationPrompts', options.method, options.body, options.headers);
        return response
    }

    const regenerateCaption = async (regenerateModel) => {
        const options = {
            method: 'POST',
            body: regenerateModel,
            headers: { 'content-Type ': 'application/json' },
        };
        const response = await fetchData('/regenerate_caption', options.method, options.body, options.headers);
        return response
    }

    const fulfillGeneration = async (userInput, result, generationDetails) => {
        const options = {
            method: 'POST',
            body: {
                userInput: userInput,
                result: result,
                generationDetails: generationDetails
            },
            headers: { 'content-Type ': 'application/json' },
        };
        const response = await fetchData('/fulfillGeneration', options.method, options.body, options.headers);
        return response
    }

    const syncGeneration = async (fulfilledAt, result, imageObject) => {
        const options = {
            method: 'POST',
            body: {
                id: user.user.id,
                fulfilledAt: fulfilledAt,
                result: result,
                imageObject: imageObject
            },
            headers: { 'content-Type ': 'application/json' },
        };
        const response = await fetchData('/syncGeneration', options.method, options.body, options.headers);
        return response
    }

    const getHistory = async (id) => {
        const options = {
            method: 'POST',
            body: { id: id },
            headers: { 'Content-Type': 'text/plain' },
        };
        const response = await fetchData('/getHistory', options.method, options.body, options.headers);
        return response
    }

    const getNotifications = async (id) => {
        const options = {
            method: 'GET',
            body: null,
            headers: [],
        };
        const response = await fetchData(`/get_notifications?id=${id}`, options.method, options.body, options.headers);
        return response
    }

    const dismissNotifications = async (notificationId) => {
        const options = {
            method: 'POST',
            body: { id: user.user.id, notificationId: notificationId },
            headers: { 'content-Type ': 'application/json' },
        }
        const response = await fetchData('/dismiss_notification', options.method, options.body, options.headers);
        return response
    }

    const redeemCode = async (code) => {
        const body = {
            code: code,
            email: user.hasOwnProperty('session') ? user.session.user.email : user.user.email,
        };

        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        const res = await fetchData('/redeem_code', options.method, options.body, options.headers);
        return res
    }

    const sendSupportEmail = async (type, title, message) => {
        const body = {
            uuid: user.user.id,
            email: user.hasOwnProperty('session') ? user.session.user.email : user.user.email,
            type: type,
            title: title,
            message: message
        };

        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        const res = await fetchData('/send_email', options.method, options.body, options.headers);
        return res
    }

    const postFacebookInfo = async (profile, page) => {
        const body = {
            id: user.user.id,
            profileInfo: profile,
            pageInfo: page,
        };

        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        const res = await fetchData('/post_facebook_info', options.method, options.body, options.headers);
        return res
    }

    const getSelectedFacebookPages = async () => {
        const options = {
            method: 'GET',
            body: null,
            headers: [],
        };
        const response = await fetchData(`/get_facebook_pages?id=${user.user.id}&profile_id=${selectedProfile.uuid ? selectedProfile.id : 0}`, options.method, options.body, options.headers);
        return response
    }

    const linkSocialPage = async (platform, name, id) => {
        const body = {
            uuid: user.user.id,
            integrated_page_name: name,
            integrated_page_id: id,
            profile_id: selectedProfile.uuid ? selectedProfile.id : 0,
            integration_type: platform
        };

        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        const res = await fetchData('/link_social_page', options.method, options.body, options.headers);
        retrieveSession()
        return res
    }

    const logError = async (error, userAttributes) => {
        const body = {
            error: [error.name, error.message, error.response],
            user: user,
            id: user.user ? user.user.id : "null",
        };

        const options = {
            method: 'POST',
            body: body,
            headers: { 'Content-Type': 'application/json' },
        };

        const res = await fetchData('/error', options.method, options.body, options.headers);
        return res
    }

    const createAgencyProfile = async (attributes) => {
        const userAttributes = {
            ...attributes,
            id: user.user.id,
        };
        const options = {
            method: 'POST',
            body: userAttributes,
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData('/create_agency_profile', options.method, options.body, options.headers);
        retrieveSession();
        return response;
    }

    const selectProfile = async (profile) => {
        const object = {
            id: profile.uuid ? profile.id : 0,
            uuid: user.user.id,
        };
        const options = {
            method: 'POST',
            body: object,
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData('/select_profile', options.method, options.body, options.headers);
        retrieveSession();
        return response;
    }

    const downloadCalendar = async (result, date) => {
        const object = {
            result: result,
            uuid: user.user.id,
            date: date
        }
        const options = {
            method: 'POST',
            body: object,
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData('/download_calendar', options.method, options.body, options.headers);
        return response;
    }

    const deleteProfile = async (id) => {

        const options = {
            method: 'POST',
            body: null,
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData(`/delete_profile?id=${selectedProfile.id}`, options.method, options.body, options.headers);
        retrieveSession();
        return response;
    }

    const linkSocialPlatform = async (integrationType, integrationDetails = undefined, user_info = undefined) => {
        const object = {
            uuid: user.user.id,
            profile_id: selectedProfile.uuid ? selectedProfile.id : 0,
            integration_type: integrationType,
            token: integrationDetails?.accessToken ? integrationDetails.accessToken : undefined,
            integration_details: integrationDetails,
            user_info: user_info
        }
        const options = {
            method: 'POST',
            body: object,
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData('/integrate_social_platform', options.method, options.body, options.headers);
        retrieveSession()
        return response;
    }

    const unlinkIntegration = async (platform) => {
        const object = {
            profile_id: selectedProfile.uuid ? selectedProfile.id : 0,
            integration_type: platform,
            uuid: user.user.id
        }
        const options = {
            method: 'POST',
            body: object,
            headers: { 'content-Type': 'application/json' },
        };
        const response = await fetchData('/unlink_integration', options.method, options.body, options.headers);
        return response;
    }




    return (
        <AuthContext.Provider value={{
            user, getNotifications, regenerateCaption, shopify, loads, setUser, logged, setLogged, login, logout, generationsLeft,
            setGenerationsLeft, signup, loginWithSocial, createProfile, setCreateProfile, retrieveSession, userProfile, postUserInfo, getGenerationPrompts,
            fulfillGeneration, getHistory, registerFacebook, generateImages, refreshImages, refreshCaptions, shopifyConfigsVisible, shopifyButtonVisible,
            subscribeButtonVisible, facebookConfigsVisible, historyVisible, trial, profileLock, codeRedeem, redeemCode, notifications,
            setNotifications, dismissNotifications, sendSupportEmail, syncGeneration, refreshImagesCount, refreshCaptionsCount, setFacebookLink, facebookLink,
            getSelectedFacebookPages, linkSocialPage, signUpState, setSignUpState, logError, createAgencyProfile, selectedProfile, setSelectedProfile, profiles,
            selectProfile, multipleProfiles, mobile, downloadCalendar, postFacebookInfo, facebookIntegration, calendarDownload, profileCount, deleteProfile,
            refreshDay, refreshDayCount, editCalendar, setRefreshCaptionsCount, setRefreshDayCount, setRefreshImagesCount, linkSocialPlatform, instagram, unlinkIntegration,
            error, setError, dropDownNavigation, setDropDownNavigation, handleDropDownToggle, supportOpen, handleSupportClose, showTutorial, setShowTutorial
        }}>
            {mobile ?
                <MobileHeader /> :
                <Header />
            }
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;