import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import { useAuth } from '../../helpers/AuthContext';

const RedeemCode = ({ codeRedeemDialog, setCodeRedeemDialog }) => {
    const [accessCode, setAccessCode] = useState('')
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const {redeemCode} = useAuth()

    const handleRedeemCode = async () => {
        setSuccess()
        setError()
        const res = await redeemCode(accessCode)
        if (res === true) {
            setSuccess(true)
            window.location.reload();
        } else {
            setError(res)
            setSuccess(false)
        }
    }

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-10 transition-all ${codeRedeemDialog ? 'opacity-100' : 'opacity-0 pointer-events-none'
                }`}
        >
            <div className={`bg-white p-4 m-3 rounded shadow-md max-w-fit max-h-fit transition-all ${codeRedeemDialog ? " scale-100" : " scale-0"}`}>
                <div className="flex flex-col items-center align-middle py-3 h-full">
                    <div className='justify-end flex w-full mb-5 '>
                        <CloseIcon onClick={() => setCodeRedeemDialog(false)} className=' cursor-pointer bg-opacity-0 bg-socoBlue hover:bg-opacity-20 rounded-full p-1 scale-150 hover:scale-[1.75]' fontSize='large' />
                    </div>
                    <div className=' justify-between flex flex-col items-center align-middle h-full space-y-10'>
                        <h3 className="text-4xl font-bold text-socoBlue font-poppins">
                            Enter your Access Code
                        </h3>
                        <input
                            placeholder="Access Code"
                            className="w-full focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 py-3 px-4 rounded-lg border-2 focus:border-socoBlue focus:outline-none"
                            value={accessCode}
                            onChange={(e) => setAccessCode(e.target.value)}
                        />
                        {error && (
                            <Alert severity="error">
                                {error}
                            </Alert>
                        )}
                        {success && (
                            <Alert severity="success">
                                Code has been redeemed successfully!
                            </Alert>
                        )}
                        <button
                            className="bg-socoBlue px-4 py-[10px] font-poppins font-bold text-lg text-white hover:bg-opacity-95 hover:scale-105 border-2 border-socoBlue transition-all rounded-3xl"
                            onClick={handleRedeemCode}
                        >
                            Redeem Code
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedeemCode
