import { Facebook } from "@mui/icons-material";
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../Styles/GeneralStyles';
import SocoFavicon from '../../../assets/images/SocoFavicon.png';
import { useAuth } from '../../../helpers/AuthContext';
import { LoadingButton } from "@mui/lab";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://sococal.ai/">
                sococal.ai
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function LogIn() {

    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [password, setPassword] = useState('');
    const { login, logged, setLogged, retrieveSession, user, registerFacebook, loads } = useAuth()
    const [load, setLoad] = useState(false)
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        setError('');
        e.preventDefault();
        try {
            setLoad(true)
            await login(email, password);
        }
        catch (error) {
            try {
                setError(error.response.data.detail);
            } catch (error) {
                setError("An error occured, please try again later.");
            }
        }
        setLoad(false)

    }

    useEffect(() => {

        const retrieve = async () => {
            try {
                await retrieveSession();
                setLogged(true);
                setIsLoading(false);
                navigate('/generate');
            }
            catch (error) {
                await facebookUp()
                console.log(error);
            }
        }
        const facebookUp = async () => {
            try {
                await registerFacebook();
                setLogged(true);
                setIsLoading(false);
                navigate('/generate');
            }
            catch (error) {
                throw error
            }
        }
        const regex = /access_token=([^&]+)/;
        const match = window.location.hash.match(regex);
        const accessToken = match ? match[1] : null;

        if (accessToken) {
            localStorage.setItem('userToken', accessToken);
            retrieve();
        }
        else if (logged) {
            navigate('/generate');

        }
        else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logged, user]);

    return (
        <>
            {isLoading || loads ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box style={styles.Background}>
                    <Container component="main" style={styles.SmallForm}>
                        <CssBaseline />
                        <Box style={styles.div}>
                            <Avatar
                                src={SocoFavicon}
                            >
                            </Avatar>
                            <Typography style={styles.mainText}>
                                Sign in
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) => { setPassword(e.target.value) }}
                                />
                                {error && (
                                    <Alert severity="error" style={styles.alert}>
                                        {error}
                                    </Alert>
                                )}
                                <LoadingButton
                                    type="submit"
                                    loading={load}
                                    endIcon={" "}
                                    loadingPosition="end"
                                    fullWidth
                                    variant="contained"
                                    style={styles.Button}
                                >
                                    Sign In WITH EMAIL
                                </LoadingButton>
                                <Grid>
                                    <Grid item xs>
                                        <Link href="/forgotpassword" style={styles.helpers}>
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/Signup" style={styles.helpers}>
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Copyright style={styles.Copyright} />
                    </Container>
                </Box>
            )}
        </>
    );
}