import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Badge, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../Styles/headerStyle';
import SocoLogo from '../../assets/images/SocoLogo.png';
import { useAuth } from '../../helpers/AuthContext';
import BellNotifications from '../BellNotifications';
import ContactUsDialog from '../Support/ContactUs';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CableIcon from '@mui/icons-material/Cable';

const MobileHeader = () => {
    const { logged, logout, historyVisible, notifications, trial } = useAuth();
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);


    const handleDrawerToggle = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleProfilePage = () => {
        navigate('/profile');
        handleDrawerToggle();
    };

    const handleHistoryPage = () => {
        if (historyVisible) {
            navigate('/history');
        }
        handleDrawerToggle();
    };

    const handleGeneratePage = () => {
        navigate('/generate');
        handleDrawerToggle();
    };

    const handleFormOpen = () => {
        handleDrawerToggle();
        setIsOpen(true);
    };

    const handleFormClose = () => {
        setIsOpen(false);
    };

    const handleLogout = () => {
        handleDrawerToggle();
        logout();
    };

    const handleDismiss = () => {
        setNotificationsOpen(false)
    };

    const handleNotificationsClick = () => {
        setNotificationsOpen(!notificationsOpen);
    };

    const handleIntegrationsPage = () => {
        navigate("/integrations");
        handleDrawerToggle();
    }

    return (
        <>
            <AppBar style={styles.Header}>
                <Toolbar>
                    {logged && (
                        <>
                            <IconButton style={{ top: '100%' }} color="black" aria-label="menu" edge="end" onClick={handleDrawerToggle}>
                                <MenuIcon />
                            </IconButton>
                        </>
                    )}
                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <IconButton color="primary" aria-label="logo" onClick={() => navigate('/generate')}>
                            <img src={SocoLogo} alt="Logo" style={styles.Logo} />
                            <div style={{ fontSize: '6px' }}>Version 1.0.0</div>
                        </IconButton>
                    </div>
                    {logged && (
                        <>
                            <IconButton
                                aria-label="notifications"
                                onClick={handleNotificationsClick}
                                sx={{
                                    color: "#013b82",
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#000000',
                                    },
                                }}
                            >
                                {notifications.length > 0 ? (
                                    <Badge badgeContent="new" color="error">
                                        <CircleNotificationsIcon />
                                    </Badge>
                                ) : (
                                    <CircleNotificationsIcon />
                                )}
                            </IconButton>
                            <div style={{ position: 'absolute', top: '100%', right: 20, marginTop: 5 }}>
                                <BellNotifications isOpen={notificationsOpen} onClose={handleDismiss} />
                            </div>
                        </>
                    )}

                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={openDrawer}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <List>
                    {logged && (
                        <>
                            {trial && (
                                <ListItem sx={{ mt: 2 }} onClick={handleDrawerToggle}>
                                    <Button
                                        style={styles.Button}
                                        variant="contained"
                                        onClick={() =>
                                            (window.location.href = 'https://sococal.ai/collections/pricing')
                                        }
                                    >
                                        Upgrade Plan
                                    </Button>
                                </ListItem>
                            )}
                            <ListItem sx={{ mt: 10 }} button onClick={handleGeneratePage}>
                                <ListItemIcon sx={{
                                    color: "#013b82",
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#000000',
                                    },
                                }}>
                                    <CalendarMonthIcon color={!historyVisible ? 'disabled' : 'inherit'} />
                                </ListItemIcon>
                                <ListItemText primary="Generate" />
                            </ListItem>
                            <ListItem sx={{ mt: 2 }} button onClick={handleHistoryPage} disabled={!historyVisible}>
                                <ListItemIcon sx={{
                                    color: "#013b82",
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#000000',
                                    },
                                }}>
                                    <HistoryIcon color={!historyVisible ? 'disabled' : 'inherit'} />
                                </ListItemIcon>
                                <ListItemText primary="History" />
                            </ListItem>
                            <ListItem sx={{ mt: 2 }} button onClick={handleProfilePage}>
                                <ListItemIcon sx={{
                                    color: "#013b82",
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#000000',
                                    },
                                }}>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItem>
                            <ListItem sx={{ mt: 2 }} button onClick={handleFormOpen}>
                                <ListItemIcon sx={{
                                    color: "#013b82",
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#000000',
                                    },
                                }}>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Support" />
                            </ListItem>
                            <ListItem sx={{ mt: 2 }} button onClick={handleIntegrationsPage}>
                                <ListItemIcon sx={{
                                    color: "#013b82",
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#000000',
                                    },
                                }}>
                                    <CableIcon />
                                </ListItemIcon>
                                <ListItemText primary="Integrations" />
                            </ListItem>
                        </>
                    )}
                </List>
                <div style={{ flexGrow: 1 }} />
                <List>
                    <ListItem sx={{ mt: 2 }} button onClick={handleLogout}>
                        <ListItemIcon sx={{
                            color: "#013b82",
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#000000',
                            },
                        }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
            <ContactUsDialog isOpen={isOpen} onClose={handleFormClose} />
        </>
    );
};

export default MobileHeader;
