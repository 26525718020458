import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../helpers/AuthContext";

export default function ProtectedRoute() {
    const { logged, createProfile } = useAuth();


    return logged && !createProfile ? (
        <Outlet />
    ) : logged && createProfile ? (
        <Navigate to="/create-profile" replace />
    ) : (
        <Navigate to="/" replace />
    );
}
