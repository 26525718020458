import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../helpers/AuthContext";

export default function CreateUserProfile() {
    const { createProfile, logged } = useAuth();


    return createProfile && logged ?  (
        <Outlet/>
    ) : (
        <Navigate to="/generate" replace />
    );
}
