import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useState } from 'react';
import { useAuth } from '../../../helpers/AuthContext';

const ProfileSelect = ({ onSelectProfile }) => {
    const { profiles, selectedProfile, setSelectedProfile, selectProfile, mobile, multipleProfiles } = useAuth()
    const [isLoading, setIsLoading] = useState(false)

    const handleProfileChange = async (event) => {
        setIsLoading(true)
        setSelectedProfile(profiles[event.target.value]);
        selectProfile(profiles[event.target.value])
        await new Promise(resolve => setTimeout(resolve, 3000))
        setIsLoading(false)

    };

    return (
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <>
                <Select
                    disabled={isLoading || !multipleProfiles}
                    labelId="profile-select-label"
                    value={profiles.findIndex(profile => (
                        JSON.stringify(profile) === JSON.stringify(selectedProfile)
                    ))}
                    onChange={handleProfileChange}
                    margin="dense"
                    style={{ minWidth: 150, height: 40, borderRadius: 20, textAlign: 'center' }}
                >
                    {profiles.map((profile, index) => (
                        <MenuItem key={index} value={index}>
                            {profile.brandName}
                        </MenuItem>
                    ))}
                </Select>
            </>
        </div>
    );
};

export default ProfileSelect;
