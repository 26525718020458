import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import styles from '../../../Styles/GeneralStyles';
import useFetch from '../../../helpers/useFetch/useFetch';
import Alert from '@mui/material/Alert';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { fetchData } = useFetch()


  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      email: email,
    }

    const options = {
      method: 'POST',
      body: body,
      headers: { 'Content-Type': 'application/json' }
    }

    try {
      await fetchData('/reset_password', options.method, options.body, options.headers)
      setEmailSent(true);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box style={styles.Background}>
      <Container component="main" style={styles.SmallForm}>
        <CssBaseline />
        <Box style={styles.div}>
          <Typography style={styles.mainText}>Forgot your password?</Typography>
          <Typography style={styles.secondaryText}>
            Enter your email address and we'll send you a link to reset your password.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            {!emailSent && <Button type="submit" fullWidth variant="contained" style={styles.Button}>
              Send reset link
            </Button>}
            {emailSent &&
              <Alert severity="success" style={styles.alert}>
                We have sent a password reset link to your email address.
              </Alert>}
            <Link href="/login" style={styles.helpers}>
              Return to sign in
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default ForgotPassword;
