import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import styles from '../../Styles/GeneralStyles';
import useFetch from '../../helpers/useFetch/useFetch';

export default function RedeemCode() {
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const { fetchData } = useFetch();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const body = {
                code: code,
                email: email,
            };

            const options = {
                method: 'POST',
                body: body,
                headers: { 'Content-Type': 'application/json' },
            };

            const res = await fetchData('/redeem_code', options.method, options.body, options.headers);
            if (res === true) {
                setSuccess(true);
            } else { setError(res) }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleLoginClick = () => {
        navigate('/login');
        window.location.reload();
    };

    return (
        <Box style={styles.Background}>
            <Container component="main" style={styles.SmallForm}>
                <CssBaseline />
                <Box style={styles.div}>
                    <Typography style={styles.redeem}>Sococal Lifetime Access</Typography>
                    <Typography style={styles.intro}>Redeem Code</Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            label="Email"
                            type="email"
                            id="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="code"
                            label="Access Code"
                            type="text"
                            id="code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        {error && (
                            <Alert severity="error" style={styles.alert}>
                                {error}
                            </Alert>
                        )}  
                        {success && (
                            <Alert severity="success" style={styles.alert}>
                                Code has been redeemed successfully!
                            </Alert>
                        )}
                        {success && (
                            <Button
                                fullWidth
                                variant="contained"
                                style={styles.Button}
                                onClick={handleLoginClick}
                            >
                                Login
                            </Button>
                        )}
                        {!success && <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={styles.Button}
                        >
                            Redeem Code
                        </Button>}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
