


const styles = {
    Copyright: {
        marginTop: '50px',
    },
    div: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    generateDiv: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    Stepper: {
        margin: '50px 0px',
    },
    Background: {
        paddingTop: 20,
        backgroundColor: '#FFFFFF',
    },
    SmallForm: {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        padding: '20px',

    },
    MediumForm: {
        maxWidth: '700px',
        minHeight: '600px',
        maxHeight: '1500px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        padding: '30px 20px 50px 20px',
    },
    Modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    secTextPrompt: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '15px',
        color: '#000000',
        margin: '0px 0px',
        textAlign: 'center',
    },
    historyHeader: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '35px',
        color: '#013b82',
        margin: '20px 0px',
        textAlign: 'left',
    },
    historyHeaderMobile: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '20px',
        color: '#013b82',
        margin: '20px 0px',
        textAlign: 'left',
    },
    redeem: {
        padding: '0px 0px 20px 0px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '40px',
        color: '#013b82',
        margin: '0px 0px',
        textAlign: 'center',
    },
    title: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '40px',
        color: '#013b82',
        margin: '0px 0px',
        textAlign: 'center',
    },
    titleMobile: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '28px',
        color: '#013b82',
        margin: '0px 0px',
        textAlign: 'center',
    },
    mainText: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '40px',
        color: '#013b82',
        margin: '0px 0px',
        textAlign: 'left',
    },
    mainTextMobile: {
        padding: '0 px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '28px',
        color: '#013b82',
        margin: '0px 0px',
        textAlign: 'left',
    },
    dates: {
        padding: '0px 0px 20px 0px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '15px',
        color: '#000000',
        margin: '0px 0px',
        textAlign: 'left',
    },
    resultTextHeadline: {
        margin: '0px',
        padding: '0px 0px 0px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '25px',
        color: '#013b82',
    },
    resultText: {
        margin: '0px',
        padding: '0px 15px 10px 15px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '15px',
        color: '#000000',
    },
    resultTextMobile: {
        margin: '0px',
        padding: '0px 15px 10px 15px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '15px',
        color: '#000000',
    },
    helpers: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#013b82',
        fontFamily: 'Poppins, sans-serif',

    },
    intro: {
        padding: '10px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '24px',
        color: '#000000',
        margin: '5px 0px',
        alignItems: 'center',

    },
    introMobile: {
        padding: '10px 20px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '20px',
        color: '#000000',
        margin: '5px 0px',
        alignItems: 'center',

    },
    secText: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '300',
        fontSize: '20px',
        color: '#000000',
        margin: '20px 0px',
    },
    secTextMobile: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '300',
        fontSize: '17px',
        color: '#000000',
        margin: '20px 0px',
    },

    examples: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '300',
        fontSize: '15px',
        color: '#000000',
        margin: '20px 0px',
        padding: "0px 0px 15px 0px"
    },
    colorInput: {
        WebkitAppearance: "none",
        appearance: "none",
        width: "100px",
        height: "30px",
        backgroundColor: "transparent",
        border: "none",
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px 5px 5px 5px',
    },
    form: {
        marginTop: 20,
        maxWidth: '1200px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',

    },
    formMobile: {
        marginTop: 80,
        maxWidth: '1200px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',

    },
    Button: {
        backgroundColor: '#013b82',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        color: '#FFFFFF',
        margin: '20px 0px',
        alignItems: 'center',

    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px 5px 5px 5px',
        maxWidth: '200px'
    },
    subTextSignIn: {
        padding: '0px 0px 10px 0px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '300',
        fontSize: '20px',
        color: '#000000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subText: {
        padding: '0px 0px 15px 0px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '300',
        fontSize: '15px',
        color: '#000000',
    },
    subTextMobile: {
        padding: '0px 0px 15px 0px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '300',
        fontSize: '13px',
        color: '#000000',
    },
    imageText: {
        display: "flex",
        flexDirection: "row",
    },
    imageTextMobile: {
        display: "flex",
        flexDirection: "column",
    },
    gensRemaining: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: '15px',
        color: '#000000',
        margin: '0px 0px',
        textAlign: 'center',
        padding: '0px 0px 10px 0px',
    },
    shopifyButton: {
        backgroundColor: '#7AB55C', // Shopify green color
        color: '#FFFFFF', // White text color
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        margin: '20px 0px',
        display: 'flex',
    },
    redeemCodeButton: {
        backgroundColor: '#013b82', // Shopify green color
        color: '#FFFFFF', // White text color
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        margin: '20px 0px',
        display: 'flex',
    },
    progressBar: {
        paddingTop: '7px',
    },
};
export default styles;