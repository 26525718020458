import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import styles from '../../../Styles/GeneralStyles';
import useFetch from '../../../helpers/useFetch/useFetch';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { fetchData } = useFetch()
  const [token, setToken] = useState(null)

  useEffect(() => {
    const regex = /refresh_token=([^&]+)/;
    const match = window.location.hash.match(regex);
    const refresh_token = match ? match[1] : null;
    if (refresh_token && token === null) {
      setToken(refresh_token)
    }
  }, [token])


  const handleSubmit = async (e) => {

    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    else if (password.length < 6) {
      setError('Password should be at least 6 characters');
      return;
    }

    else {
      try {
        const body = {
          password: password,
          token: token
        }

        const options = {
          method: 'POST',
          body: body,
          headers: { 'Content-Type': 'application/json' }
        }

        await fetchData('/update_password', options.method, options.body, options.headers)
        setSuccess(true);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleLoginClick = () => {
    navigate('/Login');
  };

  return (
    <Box style={styles.Background}>
      <Container component="main" style={styles.SmallForm}>
        <CssBaseline />
        <Box style={styles.div}>
          <Typography style={styles.mainText}>Reset Password</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && (
              <Alert severity="error" style={styles.alert}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" style={styles.alert}>
                Password has been reset successfully!
              </Alert>
            )}
            {success && (
              <Button
                fullWidth
                variant="contained"
                style={styles.Button}
                onClick={handleLoginClick}
              >
                Login
              </Button>
            )}
            {!success && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={styles.Button}
              >
                Reset Password
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
