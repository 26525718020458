import { createClient } from "@supabase/supabase-js";

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

export const LoginWithSocial = async (provider) => {
  try {
    const { data: configurationData, error: configurationError } = await supabase
      .from("app_configurations")
      .select("metrics")
      .match({ configuration: "facebook" });

    if (configurationError) {
      console.error(configurationError);
      return { data: null, error: configurationError };
    }

    const redirectUrl = configurationData[0]?.metrics?.redirectUrl;
    const scopes = configurationData[0]?.metrics?.scopes || "public_profile email";

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: redirectUrl,
        skipBrowserRedirect: true,
        scopes: scopes,
      },
    });

    if (error) {
      console.error(error);
      return { data: null, error: error };
    }

    window.location.href = data.url;

    return { data: data, error: null };
  } catch (error) {
    console.error(error);
    return { data: null, error: error };
  }
};

