import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../helpers/AuthContext";

export default function ProtectedRoute() {
    const { logged } = useAuth();


    return !logged ? (
        <Outlet/>
    ) : (
        <Navigate to="/generate" replace />
    );
}
