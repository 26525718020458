import FacebookLogin from '@greatsumini/react-facebook-login';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useAuth } from '../../helpers/AuthContext';
import FacebookPageSelect from './FacebookPageSelect';

function LoginWithFacebook({ platform, scopes, placeholder, width, connected }) {

  const { unlinkIntegration, facebookConfigsVisible, retrieveSession, linkSocialPlatform, instagram, facebookIntegration, selectedProfile, setError } = useAuth()

  const [profileInfo, setProfileInfo] = useState({})
  const [pageInfo, setPageInfo] = useState({})
  const [load, setLoad] = useState(false)
  const [socialPages, setSocialPages] = useState(undefined)

  const handleSuccess = async (response) => {
    setPageInfo(response);
    try {
      const res = await linkSocialPlatform(platform ? platform : "Facebook", response, undefined)
      if (res == false) {
        setError("An error occured! Please approve permissions or check your Facebook page settings.")
      } else {
        setSocialPages(res)
      }
    } catch (error) {
      setError("An error occured! Please approve permissions or check your Facebook page settings.")
    }

  };

  const handleProfileSuccess = async (response) => {
    setProfileInfo(response);
    await linkSocialPlatform(platform ? platform : "Facebook", undefined, response)
  };

  const reset = async () => {
    setPageInfo({})
    setProfileInfo({})
  }

  const handleUnlink = async () => {
    setLoad(true)
    await unlinkIntegration(platform);
    await retrieveSession()
    setLoad(false)
  };


  const buttonStyle = {
    backgroundColor: '#1877F2',
    color: '#FFFFFF',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: width ? width : "200px",
    gap: '10px',
  };

  const buttonTextStyle = {
    fontWeight: 'bold',
  };

  const CustomButton = styled(LoadingButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #1877F2;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border: none;
    outline: none;
    min-width: 150px;
    transition: background-color 0.3s ease;

    svg {
      fill: #FFFFFF;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: #1462AD;
    }
  }
`;

  return (
    <>
      {(platform && platform === "Instagram") && !instagram ?
        <CustomButton disabled style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} onClick={handleUnlink} loading={load}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="#FFFFFF" d="M15.875 0H.125A.123.123 0 0 0 0 .125v15.75C0 15.888.112 16 .25 16h15.625c.138 0 .25-.112.25-.25V.125A.123.123 0 0 0 15.875 0z" />
            <path fill="#1877F2" d="M9.6 16V8h2.4l.36-2.8h-2.76V3.6c0-.8.28-1.36 1.76-1.36h.92V0h-1.4c-2.4 0-3.2 1.52-3.2 3.84V5.2H6V8h2.4v8h3.2z" />
          </svg>
          <span>Coming soon!</span>
        </CustomButton> : !platform && !facebookIntegration ?
          <CustomButton disabled style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} onClick={handleUnlink} loading={load}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path fill="#FFFFFF" d="M15.875 0H.125A.123.123 0 0 0 0 .125v15.75C0 15.888.112 16 .25 16h15.625c.138 0 .25-.112.25-.25V.125A.123.123 0 0 0 15.875 0z" />
              <path fill="#1877F2" d="M9.6 16V8h2.4l.36-2.8h-2.76V3.6c0-.8.28-1.36 1.76-1.36h.92V0h-1.4c-2.4 0-3.2 1.52-3.2 3.84V5.2H6V8h2.4v8h3.2z" />
            </svg>
            <span>Upgrade to unlock!</span>
          </CustomButton>
          : !selectedProfile?.integrations?.some(integration => (
            integration.integration_type === platform)) ?
            <FacebookLogin
              initParams={{
                version: "v16.0"
              }}
              className=' h-8'
              style={buttonStyle}
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              scope={scopes ? scopes : "public_profile, email, pages_show_list, pages_read_user_content"}
              onSuccess={handleSuccess}
              onFail={(error) => {
                console.log('Login Failed!', error);
                reset()
              }}
              onProfileSuccess={handleProfileSuccess}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#FFFFFF"
                  d="M15.875 0H.125A.123.123 0 0 0 0 .125v15.75C0 15.888.112 16 .25 16h15.625c.138 0 .25-.112.25-.25V.125A.123.123 0 0 0 15.875 0z"
                />
                <path
                  fill="#1877F2"
                  d="M9.6 16V8h2.4l.36-2.8h-2.76V3.6c0-.8.28-1.36 1.76-1.36h.92V0h-1.4c-2.4 0-3.2 1.52-3.2 3.84V5.2H6V8h2.4v8h3.2z"
                />
              </svg>
              <span style={buttonTextStyle}>{placeholder ? placeholder : "Connect Facebook"}</span>
            </FacebookLogin> :
            <CustomButton style={{ fontWeight: '600' }} onClick={handleUnlink} loading={load}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="#FFFFFF" d="M15.875 0H.125A.123.123 0 0 0 0 .125v15.75C0 15.888.112 16 .25 16h15.625c.138 0 .25-.112.25-.25V.125A.123.123 0 0 0 15.875 0z" />
                <path fill="#1877F2" d="M9.6 16V8h2.4l.36-2.8h-2.76V3.6c0-.8.28-1.36 1.76-1.36h.92V0h-1.4c-2.4 0-3.2 1.52-3.2 3.84V5.2H6V8h2.4v8h3.2z" />
              </svg>
              <span>{!load ? `Unlink ${platform} Page` : "      "}</span>
            </CustomButton>
      }
      <FacebookPageSelect pages={socialPages} platform={platform} />
    </>
  );
}

export default LoginWithFacebook;
