import React, { useEffect, useState } from 'react'
import { useAuth } from '../../helpers/AuthContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import "react-color-palette/lib/css/styles.css";
import { ColorPicker, useColor, toColor } from "react-color-palette";
import ProfileSelect from '../../components/Profile/CreateProfile/ProfileSelector';
import CreateProfileDialog from '../../components/Profile/CreateProfile/CreateProfileDialog';
import RedeemCode from '../../components/Dialogs/RedeemCode';
import ErrorBanner from '../../components/Error/ErrorBanner';

const ProfileManagement = () => {

    const { user, userProfile, setUser, profileLock, codeRedeem, redeemCode, selectedProfile, profiles, multipleProfiles, facebookIntegration, deleteProfile, setError } = useAuth();
    const matches = useMediaQuery('(min-width:768px)');

    const [brandName, setBrandName] = useState(selectedProfile.brandName)
    const [brandDescription, setBrandDescription] = useState(selectedProfile.brandDescription)
    const [website, setWebsite] = useState(selectedProfile.brandWebsite)
    const [idealCustomer, setIdealCustomer] = useState(selectedProfile.idealCustomer)
    const [idealCustomerInterests, setIdealCustomerInterests] = useState(selectedProfile.idealCustomerInterests)
    const [promotionType, setpromotionType] = useState(selectedProfile.type)
    const [color1, setColor1] = useColor("hex", selectedProfile.brandColors[0]);
    const [color2, setColor2] = useColor("hex", selectedProfile.brandColors[1]);
    const [color3, setColor3] = useColor("hex", selectedProfile.brandColors[2]);
    const [toneOfVoice, setToneOfVoice] = useState(selectedProfile.toneOfVoice)
    const [visualStyle, setVisualStyle] = useState(selectedProfile.visualStyle)
    const [selectedLanguage, setSelectedLanguage] = useState(selectedProfile.language)
    const languages = ['English', 'Dutch', 'Spanish', 'Danish', 'French', 'Italian', 'Arabic', 'German', 'Portuguese', 'Swedish'];
    const [codeRedeemDialog, setCodeRedeemDialog] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        console.log(selectedProfile.brandColors, [color1.hex, color2.hex, color3.hex])
        const setStates = async () => {
            await selectedProfile
            setBrandName(selectedProfile.brandName)
            setBrandDescription(selectedProfile.brandDescription)
            setWebsite(selectedProfile.brandWebsite)
            setIdealCustomer(selectedProfile.idealCustomer)
            setIdealCustomerInterests(selectedProfile.idealCustomerInterests)
            setpromotionType(selectedProfile.type)
            setToneOfVoice(selectedProfile.toneOfVoice)
            setVisualStyle(selectedProfile.visualStyle)
            setSelectedLanguage(selectedProfile.language)
            setColor1(toColor("hex", selectedProfile.brandColors[0]))
            setColor2(toColor("hex", selectedProfile.brandColors[1]))
            setColor3(toColor("hex", selectedProfile.brandColors[2]))
        }
        setStates()
    }, [selectedProfile])

    const handleSave = async () => {
        if (
            !brandName ||
            !brandDescription ||
            !website ||
            !idealCustomer ||
            !idealCustomerInterests ||
            !toneOfVoice ||
            !visualStyle
        ) {
            setError("Please Fill all Fields!")
            return;
        }
        setIsLoading(true);
        const id = user.userProfile.length ? user.userProfile[0].id : user.auth_user.id;
        const colors = [color1.hex, color2.hex, color3.hex];
        const userAttributes = {
            "id": id,
            "brandName": brandName,
            "brandDescription": brandDescription,
            "brandWebsite": website,
            "idealCustomer": idealCustomer,
            "idealCustomerInterests": idealCustomerInterests,
            "visualStyle": visualStyle,
            "brandColors": colors,
            "toneOfVoice": toneOfVoice,
            "language": selectedLanguage,
            "brandNameChange": selectedProfile.brandName === brandName ? true : false,
            "type": promotionType
        };
        const updatedUser = {
            ...user,
            userProfile: [userAttributes],
        };

        try {
            setUser(updatedUser);
            await userProfile(userAttributes);
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    const handleProfileDelete = async () => {
        deleteProfile()
    }

    return (
        <div className={`flex justify-center items-center min-h-[100vh] prof:mt-8 mt-20 ${isLoading && " select-none"}`}>
            <RedeemCode codeRedeemDialog={codeRedeemDialog} setCodeRedeemDialog={setCodeRedeemDialog} />
            <div className='flex flex-col justify-center items-center w-[1500px] min-w-screen shadow-socoBlue mx-2 h-fit'>
                <div className='flex sm:flex-row flex-col sm:space-y-0 space-y-5 space-x-2 mb-4 justify-center items-center '>
                    <div>
                        <ProfileSelect profiles={profiles} />
                    </div>
                    <div className=' flex flex-row space-x-2'>
                        <CreateProfileDialog />
                        {selectedProfile.uuid && multipleProfiles && <button onClick={handleProfileDelete} className="px-4 py-[7px] bg-red-500 text-socoBlue border-2 font-poppins font-bold border-socoBlue rounded-2xl">
                            Delete Profile
                        </button>}
                    </div>
                </div>
                {codeRedeem && <div>
                    <button onClick={() => setCodeRedeemDialog(true)} className="px-4 py-[7px] mb-5 bg-red-500 text-socoBlue border-2 font-poppins font-bold border-socoBlue rounded-2xl">
                        Redeem AppSumo Code
                    </button>
                </div>}
                <div className="space-y-4 w-full sm:px-10 px-2 flex prof:flex-row flex-col justify-center prof:space-x-5">
                    <div className='bg-white bg-opacity-[0.05] border-[1px] border-black border-opacity-20 rounded-2xl p-8 space-y-4 w-full'>
                        <h3 className="text-2xl font-bold text-socoBlue font-poppins pt-2 -translate-x-2">Brand Information</h3>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Brand Name</h3>
                            <input disabled={(profileLock && !selectedProfile.brandNameChange)} className={` ${profileLock && !selectedProfile.brandNameChange ? "bg-opacity-0 bg-white" : "focus:border-socoBlue focus:outline-none border-2 focus:bg-opacity-10"}  w-full focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all py-3 px-4 rounded-lg`} value={brandName} onChange={(e) => setBrandName(e.target.value)} />
                        </div>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Website</h3>
                            <input className="w-full py-3 px-4 rounded-lg border-2 focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 focus:border-socoBlue focus:outline-none" value={website} onChange={(e) => setWebsite(e.target.value)} />
                        </div>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Brand Description</h3>
                            <input autoFocus className="w-full py-3 px-4 rounded-lg border-2 focus:outline-none focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 focus:border-socoBlue" value={brandDescription} onChange={(e) => setBrandDescription(e.target.value)} />
                        </div>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Ideal Customer</h3>
                            <input className="w-full py-3 px-4 rounded-lg border-2 focus:outline-none focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 focus:border-socoBlue" value={idealCustomer} onChange={(e) => setIdealCustomer(e.target.value)} />
                        </div>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Ideal Customer Interests</h3>
                            <input className="w-full py-3 px-4 rounded-lg border-2 focus:outline-none focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 focus:border-socoBlue" value={idealCustomerInterests} onChange={(e) => setIdealCustomerInterests(e.target.value)} />
                        </div>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Tone of Voice</h3>
                            <input className="w-full py-3 px-4 rounded-lg border-2 focus:outline-none focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 focus:border-socoBlue" value={toneOfVoice} onChange={(e) => setToneOfVoice(e.target.value)} />
                        </div>
                        <div>
                            <h3 className="text-md font-semibold font-poppins mb-2">Visual Style</h3>
                            <input className="w-full py-3 px-4 rounded-lg border-2 focus:outline-none focus:bg-[#2E8BC0] focus:scale-[1.02] transition-all focus:bg-opacity-10 focus:border-socoBlue" value={visualStyle} onChange={(e) => setVisualStyle(e.target.value)} />
                        </div>
                    </div>
                    <div className=' space-y-5'>
                        <div className='bg-white bg-opacity-[0.05] shadow-lg border-[1px] border-black border-opacity-20 rounded-2xl '>
                            <h3 className="text-2xl font-bold text-socoBlue font-poppins pt-8 pl-8">Brand Colors</h3>
                            <div className='flex py-5 md:flex-row justify-center items-center flex-col'>
                                <div className='m-4 shadow-md rounded-md'>
                                    <ColorPicker width={230} height={matches ? 150 : 50} color={color1} onChange={setColor1} hideRGB hideHSV />
                                </div>
                                <div className='m-4 shadow-md rounded-md'>
                                    <ColorPicker width={230} height={matches ? 150 : 50} color={color2} onChange={setColor2} hideRGB hideHSV />
                                </div>
                                <div className='m-4 shadow-md rounded-md'>
                                    <ColorPicker width={230} height={matches ? 150 : 50} color={color3} onChange={setColor3} hideRGB hideHSV />
                                </div>
                            </div>
                        </div>
                        <div className='bg-white bg-opacity-[0.05] border-[1px] border-black border-opacity-20 rounded-2xl'>
                            <h3 className="text-2xl font-bold text-socoBlue font-poppins pt-8 pl-8">Are you Selling Products or Services?</h3>
                            <div className="space-x-6 flex justify-center py-5  shadow-lg">
                                <button onClick={(e) => setpromotionType("product")} className={`px-4 font-poppins py-2 transition-all border-2 rounded-2xl ${promotionType === "product" ? "bg-socoBlue text-white scale-125" : "bg-socoBlue bg-opacity-10 text-socoBlue border-socoBlue"}`}>
                                    Products
                                </button>
                                <button onClick={(e) => setpromotionType("service")} className={`px-4 font-poppins py-2 transition-all border-2 rounded-2xl ${promotionType === "service" ? "bg-socoBlue text-white scale-125" : "bg-socoBlue bg-opacity-10 text-socoBlue border-socoBlue"}`}>
                                    Services
                                </button>
                            </div>
                        </div>
                        <div className='bg-white bg-opacity-[0.05] border-[1px] border-black border-opacity-20 rounded-2xl p-8'>
                            <h3 className="text-2xl font-bold text-socoBlue font-poppins py-4">Language</h3>
                            <div className="grid grid-cols-2 lg:grid-cols-5 gap-2">
                                {languages.map((language, index) => (
                                    <div
                                        key={index}
                                        onClick={() => setSelectedLanguage(language)}
                                        className={`${language === selectedLanguage ? "scale-105 bg-socoBlue text-white" : "bg-socoBlue bg-opacity-10 cursor-pointer"} select-none py-2 transition-all border-socoBlue border-[1px] rounded-2xl font-poppins text-center`}
                                    >
                                        {language}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ErrorBanner className=' text-[50px] py-5' />
                    <button onClick={handleSave} disabled={isLoading} className={`${isLoading ? "bg-gray text-socoBlue scale-90" : "scale-100 bg-socoBlue"}  px-4 my-4 py-[10px] font-poppins font-bold text-lg text-white hover:bg-opacity-95 hover:scale-105 border-2 border-socoBlue transition-all rounded-3xl`}>
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileManagement