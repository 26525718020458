import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import SocoFavicon from '../../../assets/images/SocoFavicon.png';
import { useAuth } from '../../../helpers/AuthContext';
import styles from '../../../Styles/GeneralStyles';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';


function Signup() {
    const { signup, setSignUpState } = useAuth()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');
    const [load, setLoad] = useState(false)



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(true)
        setError('');
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoad(false)
            return;
        }
        // password should be at least 6 characters
        if (password.length < 6) {
            setError('Password should be at least 6 characters');
            setLoad(false)
            return;
        }   
        try {
            const res = await signup(firstName, lastName, email, password);
            if (res === "err") {
                setError("User Exists. Please Login");
            } else{
                setSignUpState(true)
            }
        } catch (error) {
            setError('User Exists. Please Login');
        }
        setLoad(false)
    }

    return (
        <Box style={styles.Background}>
            <Container component="main" style={styles.SmallForm}>
                <CssBaseline />
                <Box style={styles.div}>
                    <Avatar
                        src={SocoFavicon}
                    >
                    </Avatar>
                    <Typography style={styles.mainText}>
                        Sign up
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    onChange={(e) => { setFirstName(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            onChange={(e) => { setPassword(e.target.value) }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="new-password"
                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                        />
                        {error && <Alert severity="error">{error}</Alert>}
                        <LoadingButton
                            type="submit"
                            loading={load}
                            endIcon={" "}
                            loadingPosition="end"
                            fullWidth
                            variant="contained"
                            style={styles.Button}
                        >
                            Sign Up
                        </LoadingButton>
                    </form>
                    <Grid item>
                        <Link href="/Login" style={styles.helpers}>
                            Already have an account? Sign In
                        </Link>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default Signup;
