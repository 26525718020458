import React, { useEffect, useState } from 'react'
import { Edit, Save, Replay } from '@mui/icons-material'
import { useAuth } from '../../helpers/AuthContext'
import { CircularProgress } from '@mui/material'
import styles from '../../Styles/GeneralStyles'

const DayOutputBox = ({ day, index, results, setResults, fulfilledAt, userInput, imageArr, setImageArr, englishPrompts, prompts, setEnglishPrompts }) => {
    const [editMode, setEditMode] = useState(false)
    const [editedDay, setEditedDay] = useState(day)
    const [visualLoad, setVisualLoad] = useState(false)
    const [captionLoad, setCaptionLoad] = useState(false)
    const [imageLoad, setImageLoad] = useState(false)
    const { mobile, syncGeneration, regenerateCaption, user, selectedProfile, generateImages, trial, refreshImages, refreshDay, refreshCaptions,
        refreshImagesCount, refreshCaptionsCount, refreshDayCount, editCalendar, setRefreshCaptionsCount, setRefreshDayCount, setRefreshImagesCount
    } = useAuth()
    const { Configuration, OpenAIApi } = require("openai");
    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    const openai = new OpenAIApi(configuration);

    const handleVisualIdeaEdit = (e) => {
        const updatedDay = [...editedDay]
        updatedDay[1] = e.target.value
        setEditedDay(updatedDay)
        const updatedCalendar = [...results]
        updatedCalendar[index] = updatedDay
        setResults(updatedCalendar)
        if (selectedProfile.language == "English") {
            const updatedDay = [...editedDay]
            updatedDay[1] = e.target.value
            const updatedEnglish = [...englishPrompts]
            updatedEnglish[index] = updatedDay
            setEnglishPrompts(updatedEnglish)
        }
    }

    const handleCaptionEdit = (e) => {
        const updatedDay = [...editedDay]
        updatedDay[0] = e.target.value
        setEditedDay(updatedDay)
        const updatedCalendar = [...results]
        updatedCalendar[index] = updatedDay
        setResults(updatedCalendar)
    }

    const handleEditMode = (e) => {
        setEditMode(!editMode)
        if (editMode) {
            syncGeneration(fulfilledAt, results.filter(item => item !== null), [])

        }
    }

    const handleDayRegeneration = async () => {
        setImageLoad(true)
        setCaptionLoad(true)
        setVisualLoad(true)
        setRefreshDayCount(refreshDayCount - 1)

        const res = await openai.createChatCompletion({ model: "gpt-3.5-turbo", messages: prompts[index], temperature: 1 })

        const updatedCalendar = results

        let temp = res.data.choices[0].message.content.split(/(?:Caption:)/i)
        let cap = temp[1]
        let vis = temp[0].split(/(?:Visual Idea:)/i)[1]

        if (selectedProfile.language === "English") {
            const updatedDay = editedDay
            updatedDay[0] = cap
            updatedDay[1] = vis
            setEditedDay(updatedDay)
            updatedCalendar[index] = updatedDay
            setResults(updatedCalendar)
        }
        const updatedEnglishPrompts = englishPrompts
        updatedEnglishPrompts[index][0] = cap
        updatedEnglishPrompts[index][1] = vis
        setEnglishPrompts(updatedEnglishPrompts)

        if (selectedProfile.language === "English") {
            syncGeneration(fulfilledAt, updatedCalendar.filter(item => item !== null), [])
        } else {
            for (let j = 0; j < 2; j++) {
                const res = await openai.createChatCompletion({ model: "gpt-3.5-turbo", messages: [{ "role": "user", "content": "Translate all the english words to " + selectedProfile.language + " while keeping it sound native\n\n" + englishPrompts[index][j] }], temperature: 0 })

                const updatedDay = editedDay
                updatedDay[j] = res.data.choices[0].message.content
                setEditedDay(updatedDay)
                const updatedCalendar = results
                updatedCalendar[index] = updatedDay
                setResults(updatedCalendar)
            }
            syncGeneration(fulfilledAt, updatedCalendar.filter(item => item !== null), [])
        }
        if (generateImages) {
            handleImageRegeneration()
        }
        setCaptionLoad(false)
        setVisualLoad(false)

    }

    const handleCaptionRegeneration = async (e) => {
        setCaptionLoad(true)
        setRefreshCaptionsCount(refreshCaptionsCount - 1)

        const regenerateCaptionModel = {
            ...userInput,
            visualIdea: editedDay[1]
        };
        const prompt = await regenerateCaption(regenerateCaptionModel)
        const res = await openai.createChatCompletion({ model: "gpt-3.5-turbo", messages: prompt, temperature: 1 })
        let temp = res.data.choices[0].message.content.split(/(?:Caption:)/i)
        let cap = temp[1]
        if (selectedProfile.language !== "English") {
            const res = await openai.createChatCompletion({ model: "gpt-3.5-turbo", messages: [{ "role": "user", "content": "Translate all the english words to " + selectedProfile.language + " \n\n" + cap }], temperature: 0 })
            cap = res.data.choices[0].message.content
        }
        const updatedDay = editedDay
        updatedDay[0] = cap
        setEditedDay(updatedDay)
        const updatedCalendar = results
        updatedCalendar[index] = editedDay
        setResults(updatedCalendar)
        await new Promise(resolve => setTimeout(resolve, 500))
        syncGeneration(fulfilledAt, updatedCalendar.filter(item => item !== null), imageArr)
        setCaptionLoad(false)
    }

    const handleImageRegeneration = async () => {
        setImageLoad(true)
        setRefreshImagesCount(setRefreshImagesCount - 1)
        const urls = [
            process.env.REACT_APP_COLOR_API + user.userProfile[0].brandColors[0].substring(1),
            process.env.REACT_APP_COLOR_API + user.userProfile[0].brandColors[1].substring(1),
            process.env.REACT_APP_COLOR_API + user.userProfile[0].brandColors[2].substring(1),
        ];
        let colorNames
        await Promise.all(
            urls.map(url => fetch(url).then(response => response.json()))
        )
            .then(data => {
                colorNames = data.map(color => color.name.value)
            })
        const res = await openai.createImage({ prompt: "Generate an image that depicts a scene with minimal focus on human faces, but instead highlights the environment, objects, and context. The scene should include " + (englishPrompts[index][1] + `Use the following colors: ${colorNames[0]}, ${colorNames[1]}, and ${colorNames[2]}, to create a consistent theme.`), n: 1, size: "1024x1024" })
        const updatedDay = editedDay
        updatedDay[3] = res.data.data[0].url
        const updatedImages = imageArr
        updatedImages[index] = res
        setImageArr(updatedImages)
        setEditedDay(updatedDay)
        const updatedCalendar = results
        updatedCalendar[index] = updatedDay
        setResults(updatedCalendar)
        syncGeneration(fulfilledAt, updatedCalendar.filter(item => item !== null), updatedImages)
        setImageLoad(false)

    }

    return (
        <>
            {
                <>
                    <div className=' transition-transform align text-left py-5 font-poppins px-10 rounded-3xl flex flex-col mb-9 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] scale-[1.02] hover:scale-[1.04] ease-linear duration-200 bg-white lg:flex-row'>
                        {index === Math.floor(results.length / 2) && trial && <div style={{ ...styles.mainText, fontWeight: "700", color: "#282C35", position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", filter: "blur(0px)", textAlign: "center", padding: 5 }}>
                            <a target='_blank' href='https://sococal.ai/pages/plans-pricing' className='scale-105'>
                                Upgrade to unlock the full experience!
                                <p style={{ fontSize: 20 }}>Your generation history will be available after you upgrade</p>
                                <p style={{ fontSize: 25, textDecorationLine: "underline" }}>Click here</p>
                            </a>
                        </div>}
                        <div className={`align-middle flex-shrink-1 ${index >= Math.floor(results.length / 2) && trial && "blur-[3.5px] select-none pointer-events-none"} `}>
                            <div className='flex flex-row justify-between'>
                                <h3 className='text-lg font-black text-black text-center pb-5'>
                                    Day {index + 1}
                                </h3>
                                <h3 className='text-lg font-black text-black text-center pb-5'>
                                    {day[2]}
                                </h3>
                            </div>
                            {editMode || !refreshDay || refreshDayCount <= 0 ?
                                <h3 className='text-2xl font-black text-socoBlue '>
                                    Visual Idea :
                                </h3> : visualLoad ?
                                    <div className='flex text-2xl font-black text-socoBlue transition-all rounded-full w-fit group'>
                                        <h3>
                                            Visual Idea
                                        </h3>
                                        <CircularProgress size={25} />
                                    </div>
                                    :
                                    <div onClick={handleDayRegeneration} className='flex hover:my-2 hover:bg-socoBlue text-2xl font-black text-socoBlue group hover:scale-110 transition-all hover:border-2 hover:border-black rounded-full hover:px-2 hover:cursor-pointer w-fit group'>
                                        <h3 className=' group-hover:scale-0 group-hover:absolute'>
                                            Visual Idea
                                        </h3>
                                        <h3 className='scale-0 group-hover:scale-75 absolute group-hover:relative text-white'>
                                            Regenerate Day
                                        </h3>
                                        <Replay className=' scale-50 group-hover:scale-0 group-hover:absolute' />
                                        <h3 className='group-hover:scale-0 group-hover:absolute'>:</h3>
                                    </div>}
                            {editMode ?
                                <textarea style={{ direction: selectedProfile.language === "Arabic" ? 'rtl' : "" }} onChange={handleVisualIdeaEdit} value={editedDay[1]} rows={mobile ? 8 : 6} cols={130} className='pl-2 h-auto resize-none w-[100%] border-2 rounded-md' />
                                :
                                <p style={{ direction: selectedProfile.language === "Arabic" ? 'rtl' : "" }} className='pl-2'>
                                    {editedDay[1]}
                                </p>
                            }
                            {editMode || !refreshCaptions || refreshCaptionsCount <= 0 ?
                                <h3 className='text-2xl font-black text-socoBlue '>
                                    Caption :
                                </h3> : captionLoad ?
                                    <div className='flex text-2xl font-black text-socoBlue transition-all rounded-full w-fit group'>
                                        <h3>
                                            Caption
                                        </h3>
                                        <CircularProgress size={25} />
                                    </div>
                                    :
                                    <div onClick={handleCaptionRegeneration} className='flex hover:my-2 hover:bg-socoBlue text-2xl font-black text-socoBlue group hover:scale-110 transition-all hover:border-2 hover:border-black rounded-full hover:px-2 hover:cursor-pointer w-fit group'>
                                        <h3 className='group-hover:scale-0 group-hover:absolute'>
                                            Caption
                                        </h3>
                                        <h3 className='scale-0 group-hover:scale-75 absolute group-hover:relative text-white'>
                                            Regenerate Caption
                                        </h3>
                                        <Replay className=' scale-50 group-hover:scale-0 group-hover:absolute' />
                                        <h3 className='group-hover:scale-0 group-hover:absolute'>:</h3>
                                    </div>}
                            {editMode ?
                                <textarea style={{ direction: selectedProfile.language === "Arabic" ? 'rtl' : "" }} onChange={handleCaptionEdit} value={editedDay[0]} rows={mobile ? 10 : 6} cols={130} className='pl-2 h-auto resize-none w-[100%] border-2 rounded-md' />
                                :
                                <p style={{ direction: selectedProfile.language === "Arabic" ? 'rtl' : "" }} className='pl-2'>
                                    {editedDay[0]}
                                </p>
                            }
                            {editCalendar && <div className='flex justify-center pt-8'>
                                <button disabled={(imageLoad || captionLoad || visualLoad || !editCalendar) ? true : false} onClick={handleEditMode} className={`hover:scale-110 ${!editCalendar && "group"} hover:bg-socoBlue hover:text-white transition-all bg-white border-socoBlue border-2 border-opacity-40 shadow-sm shadow-black text-socoBlue rounded-full px-3 py-1 font-medium`}>
                                    {editMode ?
                                        <>
                                            <Save className='pr-1' />
                                            Save Changes
                                        </>
                                        :
                                        <>
                                            <Edit className='pr-1' />
                                            Edit Text
                                        </>
                                    }
                                </button>
                            </div>}
                        </div>
                        {generateImages && <div>
                            {editMode || !refreshImages || setRefreshImagesCount <= 0 ?
                                <div className='flex-shrink-0 flex justify-center py-5 lg:p-10 z-20'>
                                    <img className='transition-all min-w-[200px] min-h-[200px] lg:min-w-[300px] lg:min-h-[300px] lg:max-w-[300px] lg:max-h-[300px] rounded-3xl shadow-xl' src={editedDay[3]} alt='Generating Image' />
                                </div> : imageLoad || !editedDay[3] ?
                                    <div className='relative flex-shrink-0 flex justify-center py-5 lg:p-10 z-20'>
                                        <div className='absolute scale-100 text-black bottom-[30%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] z-10 font-poppins font-bold text-center'>
                                            <CircularProgress size={80} />
                                        </div>
                                        <img className='transition-all opacity-50 min-w-[200px] min-h-[200px] lg:min-w-[300px] lg:min-h-[300px] rounded-3xl shadow-xl' src={editedDay[3]} />
                                    </div> :
                                    <div onClick={handleImageRegeneration} className='flex-shrink-0 flex justify-center py-5 lg:p-10 group z-20'>
                                        <button className='relative'>
                                            <div className='absolute scale-0 text-black bottom-[40%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] z-10 group-hover:scale-150 font-poppins font-bold text-center'>
                                                <h3>Regenerate Image</h3>
                                                <Replay />
                                            </div>
                                            <Replay className='absolute left-[94%] group-hover:scale-0 z-20 ' />
                                            <img className='transition-all min-w-[200px] min-h-[200px] lg:min-w-[300px] lg:min-h-[300px] group-hover:bg-black group-hover:opacity-50 rounded-3xl shadow-xl' src={editedDay[3]} />
                                        </button>
                                    </div>}
                        </div>}
                    </div >
                </>
            }
        </>
    )
}

export default DayOutputBox