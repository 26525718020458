import { useState, useCallback } from 'react';
import api from '../api';

function useFetch() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async (url, method = 'GET', data = null, options = {}) => {
    setLoading(true);
    try {
      const response = await api({
        url,
        method,
        data,
        ...options,
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      setError(error);
      throw error;
    }
  }, []);

  return { loading, error, fetchData };
}


export default useFetch;
